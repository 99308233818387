<ngx-img capture="user"
         (onSelect)="onSelect($event)"
         (onReset)="onReset()"
         [config]="config"></ngx-img>
<mat-icon *ngIf="!hasSrc"
          class="close-img-uploader-icon"
          (click)="onClose()">close
</mat-icon>
<button *ngIf="!selectOnly"
        class="img-btn"
        type="button"
        [disabled]="!hasSrc"
        (click)="onUpload()">
  {{'general.actions.upload' | translate}}
</button>
