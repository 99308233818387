import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-estimate-group-stepper',
  templateUrl: './estimate-group-stepper.component.html',
  styleUrls: ['./estimate-group-stepper.component.scss']
})
export class EstimateGroupStepperComponent implements OnInit {

  @Input() total: any;
  @Input() selectedIndex: number;

  @Output() stepperDone = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  selectionChangeStepper(event: any) {
    this.selectionChange.emit(event.selectedIndex);
  }
}
