<mat-toolbar>
  <app-icon-button class="hamburger"
                   type="simple"
                   (appClick)="sidenav.toggle()">
    menu
  </app-icon-button>
  <a class="home"
     [routerLink]="home">
    <img [src]="logo"
         alt="Appmake Logo">
  </a>

  <div class="options">
    <app-icon-button
      class="theme-icon"
      (click)="onOptionToggle()"
      [selected]="isOptionSelected(theme)">
      {{ computeIcon(theme) }}
    </app-icon-button>
    <app-icon-button class="menu-icon"
                     mat-icon-button
                     [matMenuTriggerFor]="menu">more_vert
    </app-icon-button>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let language of languages">
        <p (click)="onLanguageChange(language)"
           class="language-item">{{ ('general.texts.languages.' + language) | translate }}</p>
      </ng-container>
    </mat-menu>
  </div>
  <div class="menu" *ngIf="tabs && tabs.length > 0">
    <ng-container *ngFor="let tab of tabs">
      <ng-container *ngTemplateOutlet="tabButton ; context: {tab: tab}"></ng-container>
    </ng-container>
  </div>
</mat-toolbar>


<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    class="sidenav"
    #sidenav mode="over"
    opened="false">
    <div class="sidenav-header">
    </div>
    <div class="sidenav-body">
      <ng-container *ngFor="let tab of tabs">
        <ng-container *ngTemplateOutlet="menuButton ; context: {tab: tab}"></ng-container>
      </ng-container>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>


<ng-template #tabButton let-tab="tab">
  <app-button
    class="hamburger"
    *ngIf="tab.visible"
    routerLinkActive="selected" #rla="routerLinkActive"
    type="tab"
    [icon]="tab.icon"
    [selected]="rla.isActive"
    [routerLink]="tab.path">
    {{ tab.label }}
  </app-button>
</ng-template>

<ng-template #menuButton let-tab="tab">
  <app-button
    *ngIf="tab.visible"
    routerLinkActive="selected" #rla="routerLinkActive"
    type="menu"
    [icon]="tab.icon"
    [selected]="rla.isActive"
    [routerLink]="tab.path"
    (appClick)="sidenav.toggle()">
    {{ tab.label }}
  </app-button>
</ng-template>
