import {AfterViewChecked, AfterViewInit, Component, ElementRef, HostBinding, Inject, ViewChild} from '@angular/core';

import {UntilDestroy} from '@ngneat/until-destroy';

import {select, Store} from '@ngrx/store';
import * as estimateReducer from './state/estimate.reducer';
import * as estimateActions from './state/estimate.actions';

import {Observable} from 'rxjs';
import {TranslateService} from "@ngx-translate/core";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import {ActivatedRoute, Router} from "@angular/router";
import {Application} from "./models/application.model";
import {filter} from "rxjs/operators";
import {isEmpty} from "lodash";

@UntilDestroy()
@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss']
})
export class EstimateComponent implements AfterViewInit {

  CUSTOM_APP_ESTIMATE_KEY = 'custom';

  isLoading$: Observable<boolean>;
  applications$: Observable<Application[]>;

  @HostBinding('class.web') get web() {
    return this.isWeb
  }

  @HostBinding('class.mobile') get mobile() {
    return !this.isWeb
  }

  isWeb = false;
  appName = '';
  loading = false;

  constructor(
    @Inject('environment') private environment: any,
    private breakpointObserver: BreakpointObserver,
    private store: Store<any>,
    private route: ActivatedRoute,
  ) {

    this.breakpointObserver.observe([
      "(max-width: 959px)"
    ]).subscribe((result: BreakpointState) => {
      this.isWeb = !result.matches;
    });

    this.route.firstChild.params.subscribe(params => {
      this.appName = params['id'] ? params['id'] : this.CUSTOM_APP_ESTIMATE_KEY;
    });

    this.applications$ = this.store.pipe(select(estimateReducer.getApps),
      filter(entries => !isEmpty(entries)));

    this.isLoading$ = this.store.pipe(select(estimateReducer.isLoading));
    this.isLoading$.subscribe(loading => {
      this.loading = loading;
    })


    this.store.dispatch(estimateActions.loadApplications());
  }


  ngAfterViewInit(): void {
    // ON mobile devices, if we open up a specific app, we want the app to scroll to the specific app
    if (!this.isWeb && this.appName) {
      this.onElementAvailable(this.appName, () => {
        this.scrollToElement(this.appName);
      });
    }
  }

  scrollToElement(appId: string) {
    let el = document.querySelector(`#${appId}`);
    if (el) {
      el.scrollIntoView(true);
    }
  }

  isExpanded(name: string) {
    return this.appName.indexOf(name) != -1;
  }


  onElementAvailable(name: string, callback: any) {
    const observer = new MutationObserver(mutations => {
      if (document.getElementById(name)) {
        observer.disconnect();
        callback();
      }
    });

    observer.observe(document.body, {childList: true, subtree: true});
  }

}
