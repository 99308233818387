import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';

import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

import { NgxImgModule } from 'ngx-img';

import { ToastrModule } from 'ngx-toastr';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';


import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';


import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';

import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppEffects } from './app.effects';
import { UxModule } from '@ngnizer/ux';
import { EffectsModule } from '@ngrx/effects';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EstimateModule } from '@ngnizer/estimate';
import {EllipsisModule} from "ngx-ellipsis";

const cookieConfig: NgcCookieConsentConfig = {
    cookie: {
        domain: environment.cookie.domain
    },
    position: 'bottom',
    theme: 'classic',
    palette: {
        popup: {
            background: '#A6A6A6',
            text: '#ffffff',
            link: '#ffffff'
        },
        button: {
            background: '#C55C51',
            text: '#ffffff',
            border: 'transparent'
        }
    },
    type: 'info',
    content: {
        href: environment.cookie.cookiePolicyURL,
    }
};

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.api.firebase),
        AngularFirestoreModule,
        StoreModule.forRoot({}, {
            metaReducers: []
        }),
        EffectsModule.forRoot([
            AppEffects
        ]),
        StoreDevtoolsModule.instrument(),
        LoggerModule.forRoot({level: NgxLoggerLevel.DEBUG}),
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        EllipsisModule,
        UxModule.forRoot(environment),
        EstimateModule.forRoot(environment),
        NgxImgModule.forRoot(),
        NgcCookieConsentModule.forRoot(cookieConfig),
        TourMatMenuModule,
        AppRoutingModule
    ],
    providers: [
        JwtHelperService,
        {provide: JWT_OPTIONS, useValue: {}}
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
