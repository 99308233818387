import {NgModule} from '@angular/core';
import {ChartModule} from "primeng/chart";
import {TreeModule} from "primeng/tree";

@NgModule({
  declarations: [],
  imports: [
    ChartModule,
    TreeModule
  ],
  exports: [
    ChartModule,
    TreeModule
  ],
  providers: []
})
export class PrimeNGLibraryModule {
}
