<h2 class="mat-headline-3">{{'ux.buttons.title' | translate}}</h2>
<h3 class="mat-headline-5">Icon Button</h3>
<div class="icon-variants">
  <app-icon-button type="simple"
                   (appClick)="isIconButtonSelected = !isIconButtonSelected"
                   [selected]="isIconButtonSelected">
    wb_sunny
  </app-icon-button>
  <app-icon-button type="material"
                   (appClick)="isIconButtonSelected = !isIconButtonSelected"
                   [selected]="isIconButtonSelected">
    dark_mode
  </app-icon-button>

  <app-selection-indicator [selected]="isSelectionIndicatorSelected"
                           (appClick)="isSelectionIndicatorSelected = !isSelectionIndicatorSelected">

  </app-selection-indicator>

  <app-icon-button type="circular"
                   (appClick)="isIconButtonSelected = !isIconButtonSelected"
                   [selected]="isIconButtonSelected">
    dark_mode
  </app-icon-button>
</div>

<h3 class="mat-headline-5">Simple Button</h3>
<div class="button-variants">
  <app-button>
    Rounded
  </app-button>
  <app-button icon="wb_sunny"
              [selected]="isRoundedButtonSelected"
              (appClick)="isRoundedButtonSelected = !isRoundedButtonSelected">
    Rounded + Icon
  </app-button>
  <app-button icon="wb_sunny"
              type="tab"
              [selected]="isTabButtonSelected"
              (appClick)="isTabButtonSelected = !isTabButtonSelected">
    Tab
  </app-button>
  <app-button icon="wb_sunny"
              type="menu"
              [selected]="isMenuButtonSelected"
              (appClick)="isMenuButtonSelected = !isMenuButtonSelected">
    Menu Button
  </app-button>
</div>
