import {Team} from "./team.model";

export class Cost {
  public team: Team;
  public isRecommended: boolean;
  public duration: number;
  public total: number;

  constructor(cost: any = {}) {
    this.team = cost?.team ? new Team(cost.team) : null;
    this.duration = cost?.duration || 0;
    this.total = cost?.total || 0;
    this.isRecommended = cost?.isRecommended || false;
  }
}

