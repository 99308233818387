import {
  AfterContentInit,
  Component,
  ContentChild,
  forwardRef,
  OnInit,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'app-material-body',
  templateUrl: './material-body.component.html',
  styleUrls: ['./material-body.component.scss']
})
export class MaterialBodyComponent implements OnInit, AfterContentInit {

  @ContentChild(forwardRef(() => 'content'), {static: false}) content: TemplateRef<any>;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
  }
}
