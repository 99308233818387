import {Injectable} from '@angular/core';

import {TranslateService} from '@ngx-translate/core';

import * as estimateActions from './estimate.actions';

import {EstimateService} from '../services';
import {combineLatest, mergeMap, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {Module} from '../models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Application} from "../models";
import {ApplicationCost} from "../models/applicationCost.model";

@Injectable()
export class EstimateEffects {

  loadApplications$ = createEffect(() => this.actions$.pipe(
    ofType(estimateActions.loadApplications),
    switchMap(() => {
      return this.estimateService.loadApplications().pipe(
        switchMap((apps: any[]) => {
          const appObservables = apps.map(app =>
            this.estimateService.loadApplicationModules(app.key).pipe(
              map(modules => ({...app, modules}))
            )
          );
          return combineLatest(appObservables);
        }),
      );
    }),
    map((appsWithModules: any[]) => {
      return estimateActions.loadApplicationsSuccess({
        apps: this.mapApps(appsWithModules)
      });
    }),
    catchError(error => {
      console.error('Error in effect:', error);
      return of(estimateActions.loadApplicationsFailed({error}));
    })
  ));

  loadApplication$ = createEffect(() => this.actions$.pipe(
    ofType(estimateActions.loadApplication),
    switchMap(action => {
      return this.estimateService.loadApplication(action.key).pipe(
        switchMap((app: any) =>
          this.estimateService.loadApplicationModules(app.key).pipe(
            switchMap(modules =>
              this.estimateService.computeApplicationCost(app.key).pipe(
                map(cost => ({...app, modules, cost}))
              )
            )
          )
        ),
        map((appWithModulesAndCosts: any) => {
          return estimateActions.loadApplicationSuccess({
            selectedApp: new Application(appWithModulesAndCosts)
          });
        }),
        catchError(error => {
          console.error('Error in effect:', error);
          return of(estimateActions.loadApplicationFailed({error}));
        })
      );
    })
  ));

  //TODO @Sorina swore that she will fix this, as it spits out multiple loadApplicationModuleSuccess events
  loadApplicationModule$ = createEffect(() => this.actions$.pipe(
    ofType(estimateActions.loadApplicationModule),
    switchMap(action => {
      return this.estimateService.loadApplicationModule(action.appKey, action.moduleKey).pipe(
        mergeMap((module: any) =>
          this.estimateService.loadApplicationModuleFeatures(action.appKey, module.key).pipe(
            switchMap(features => {
              const featuresWithTasks$ = features.map(feature =>
                this.estimateService.loadApplicationModuleFeatureTasks(action.appKey, module.key, feature.key).pipe(
                  map(tasks => ({...feature, tasks}))
                )
              );
              return combineLatest(featuresWithTasks$).pipe(
                map(featuresWithTasks => ({...module, features: featuresWithTasks}))
              );
            })
          )
        ),
        map((moduleWithFeaturesAndTasks: any) => {
          return estimateActions.loadApplicationModuleSuccess({
            selectedModule: new Module(moduleWithFeaturesAndTasks)
          });
        }),
        catchError(error => {
          console.error('Error in effect:', error);
          return of(estimateActions.loadApplicationModuleFailed({error}));
        })
      );
    })
  ));

  loadModules$ = createEffect(() => this.actions$.pipe(
    ofType(estimateActions.loadModules),
    switchMap(() => {
        return this.estimateService.loadModules().pipe(
          map((modules: any[]) => {
            return estimateActions.loadModulesSuccess({
              modules: this.mapModules(modules)
            });
          }),
          catchError(error => {
            console.error('Error in effect:', error);
            return of(estimateActions.loadModulesFailed({error}));
          }))
      }
    )));

  loadModule$ = createEffect(() => this.actions$.pipe(
    ofType(estimateActions.loadModule),
    switchMap(action => {
      return this.estimateService.loadModule(action.key).pipe(
        switchMap((module: any) =>
          this.estimateService.loadModuleFeatures(module.key).pipe(
            switchMap(features => {
              const featuresWithTasks$ = features.map(feature =>
                this.estimateService.loadModuleFeatureTasks(module.key, feature.key).pipe(
                  map(tasks => ({...feature, tasks}))
                )
              );
              return combineLatest(featuresWithTasks$).pipe(
                map(featuresWithTasks => ({...module, features: featuresWithTasks}))
              );
            })
          )
        ),
        map((moduleWithFeaturesAndTasks: any) => {
          return estimateActions.loadModuleSuccess({
            selectedModule: new Module(moduleWithFeaturesAndTasks)
          });
        }),
        catchError(error => {
          console.error('Error in effect:', error);
          return of(estimateActions.loadModuleFailed({error}));
        })
      );
    })
  ));

  calculateCustomAppCost$ = createEffect(() => this.actions$.pipe(
    ofType(estimateActions.calculateCustomAppCost),
    switchMap((action) =>
      this.estimateService.computeModulesCost(action.moduleKeys)
        .pipe(
          map((data: any) => estimateActions.calculateCustomAppCostSuccess({
            customAppCost: new ApplicationCost(data)
          })),
          catchError(error => of(estimateActions.calculateCustomAppCostFailed({error})))
        ))
  ));


  submitOfferCost$ = createEffect(() => this.actions$.pipe(
    ofType(estimateActions.submitOffer),
    switchMap((action) =>
      this.estimateService.submitOffer(action.offerRequest).pipe(
        map((data: any) => {
          return estimateActions.submitOfferSuccess(data)
        }),
        catchError(error => {
          console.error('Error in effect:', error);
          return of(estimateActions.submitOfferFailed({error}));
        }))
    )));

  private mapApps(data: any): Application[] {
    return data.map((item: any) => new Application(item));
  }

  private mapModules(data: any): Module[] {
    return data.map((item: any) => new Module(item));
  }

  constructor(private actions$: Actions,
              private translateService: TranslateService,
              private estimateService: EstimateService) {
  }
}
