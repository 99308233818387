import { Component, Inject } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { select, Store } from '@ngrx/store';
import { ActivatedRoute } from "@angular/router";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { BottomSheetComponent } from "../bottom-sheet/bottom-sheet.component";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

import * as estimateReducers from '../../state/estimate.reducer';
import * as estimateActions from '../../state/estimate.actions';

import { Application } from "../../models/application.model";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { Module } from "../../models";
import { TranslateService } from "@ngx-translate/core";
import { ApplicationCost } from "../../models/applicationCost.model";
import { ChartColumn, ChartItem } from 'libs/ngnizer/ux/src/lib/models/chart-data.model';
import { Complexity } from "../../models/complexity.model";


@UntilDestroy()
@Component({
  selector: 'app-application-details-card',
  templateUrl: './application-details-card.component.html',
  styleUrls: ['./application-details-card.component.scss']
})
export class ApplicationDetailsCardComponent {

  appName: string;

  selectedApp$: Observable<Application>;
  selectedModule$: Observable<Module>;
  isLoading$: Observable<boolean>;

  selectedApp: Application;
  selectedModule: Module;

  isWeb = false;

  // Gantt chart properties
  moduleChartData: ChartItem[] = [];
  title: string = 'Module Timeline';
  columnNames = [
    new ChartColumn({type: 'string', name: 'Module ID'}),
    new ChartColumn({type: 'string', name: 'Module Name'}),
    new ChartColumn({type: 'date', name: 'Start Date'}),
    new ChartColumn({type: 'date', name: 'End Date'}),
    new ChartColumn({type: 'number', name: 'Duration'}),
    new ChartColumn({type: 'number', name: 'Percent Complete'}),
    new ChartColumn({type: 'string', name: 'Dependencies'}),
  ];

  options = {
    width: 2000,
    height: 500,
    gantt: {
      criticalPathEnabled: false,
      trackHeight: 40,
      defaultStartDate: new Date(),
      labelStyle: {
        fontName: 'inherit',
        fontSize: 14,
        color: 'inherit'
      },
      barCornerRadius: 4
    },
    titleTextStyle: {
      fontName: 'inherit',
      fontSize: 16,
      color: 'inherit'
    }
  };

  constructor(
    @Inject('environment') private environment: any,
    private route: ActivatedRoute,
    private store: Store<any>,
    private translate: TranslateService,
    private _bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver
  ) {

    this.route.params.subscribe(params => {
      this.appName = params['id'];
      this.store.dispatch(estimateActions.loadApplication({key: this.appName}));
    });

    this.breakpointObserver.observe([
      "(max-width: 959px)"
    ]).subscribe((result: BreakpointState) => {
      this.isWeb = !result.matches;
    });


    this.isLoading$ = this.store.pipe(select(estimateReducers.isLoading));
    this.selectedApp$ = this.store.pipe(select(estimateReducers.getSelectedApp));

    this.selectedApp$.pipe(filter((app) => !!app), untilDestroyed(this))
      .subscribe((app: Application) => {
        this.selectedApp = app;
        this.generateModuleChartData(app.modules);
      });
  }

  // Generate chart data from application modules
  generateModuleChartData(modules: Module[]) {
    this.moduleChartData = [];

    // Add Phase 1 as first chart entry
    const phase1StartDate = new Date();
    // Get duration in days (assuming cost.duration is in months, convert to days)
    const phase1Duration = this.selectedApp.cost.phase1.duration * 30;
    const phase1EndDate = new Date(phase1StartDate.getTime() + this.convertDaysToMiliseconds(phase1Duration));

    this.moduleChartData.push(new ChartItem({
      id: 'phase1',
      name: "Planning",
      startDate: phase1StartDate,
      endDate: phase1EndDate,
      duration: this.convertDaysToMiliseconds(phase1Duration),
      percentComplete: 0,
      dependencies: null,
      customData: []
    }));

    // Add Phase 2 as second chart entry
    const phase2StartDate = phase1EndDate;
    const phase2Duration = this.selectedApp.cost.phase2.duration * 30;
    const phase2EndDate = new Date(phase2StartDate.getTime() + this.convertDaysToMiliseconds(phase2Duration));

    this.moduleChartData.push(new ChartItem({
      id: 'phase2',
      name: "Design",
      startDate: phase2StartDate,
      endDate: phase2EndDate,
      duration: this.convertDaysToMiliseconds(phase2Duration),
      percentComplete: 0,
      dependencies: 'phase1',
      customData: []
    }));

    // Add modules after the phases
    let currentDate = phase2EndDate;
    for (let i = 0; i < this.selectedApp.modules.length; i++) {
      const module = this.selectedApp.modules[i];
      const startDate = currentDate;
      const moduleComplexity = module.complexity;
      const moduleDuration = this.convertComplexityToDays(moduleComplexity);
      const endDate = new Date(startDate.getTime() + this.convertDaysToMiliseconds(moduleDuration));

      // Use the translate service to get the module name
      const moduleName = this.translate.instant('pages.estimate.modules.' + module.key + '.name');

      this.moduleChartData.push(new ChartItem({
        id: module.key,
        name: moduleName,
        startDate: startDate,
        endDate: endDate,
        duration: this.convertDaysToMiliseconds(moduleDuration),
        percentComplete: 0,
        dependencies: i === 0 ? 'phase2' : this.selectedApp.modules[i-1].key,
        customData: []
      }));

      currentDate = endDate;
    }

    // Update chart options to set height based on number of items
    const itemCount = this.moduleChartData.length;
    this.options = {
      ...this.options,
      height: itemCount * 40 + 80
    };
  }

  onModuleClick(module: Module) {
    this._bottomSheet.open(BottomSheetComponent, {
      panelClass: 'max-width',
      data: {
        module: module,
        app: this.appName,
        custom: false
      }
    });
  }

  computeCost(total: any) {

    const asKRepresentation = total.toLocaleString('en-US',
      {
        style: 'decimal',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        useGrouping: true
      });

    return asKRepresentation.split(',')[0] + 'K'
  }

  get team() {
    return this.selectedApp.cost.phase3.find(item => item.isRecommended == true).team;
  }

  computeFixedDuration(cost: ApplicationCost) {
    return cost.phase1.duration + cost.phase2.duration;
  }

  computeVariableDuration(cost: ApplicationCost) {
    const recommendedTeamCost = cost.phase3.find(cost => cost.isRecommended);
    return Math.round(recommendedTeamCost.duration);
  }

  computeTotalDuration(cost: ApplicationCost): any {
    return `assets/icons/duration/duration_${Math.ceil(cost.total.duration)}.svg`;
  }

  computeFixedCost(cost: ApplicationCost) {
    const fixedCost = cost.phase1.total + cost.phase2.total;
    return this.computeCost(fixedCost);
  }

  computeMonthlyCost(cost: ApplicationCost) {
    const recommendedTeamCost = cost.phase3.find(cost => cost.isRecommended);
    return this.computeCost(Math.ceil(recommendedTeamCost.team.monthlyCost));
  }

  // Helper methods for Gantt chart
  convertDaysToMiliseconds(days: number) {
    return days * 24 * 60 * 60 * 1000;
  }

  convertComplexityToDays(complexity: Complexity) {
    const daysPerSprint = 30;
    return Math.ceil(complexity.total / this.team.velocity * daysPerSprint);
  }
}
