import { inject, Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn,
  UrlTree
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export const LanguageGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | UrlTree => {
  const router = inject(Router);
  const translate = inject(TranslateService);
  const validLanguages = ['en', 'ro']; // Define supported languages

  const language = route.paramMap.get('lang');

  // If language is valid, use it and allow navigation
  if (language && validLanguages.includes(language)) {
    translate.use(language);
    return true;
  } else {
    // If no valid language, redirect to default language (e.g., 'en')
    return router.createUrlTree(['en']);
  }
};
