<app-application-card-avatar
        class="avatar"
        type="image"
        background="{{background}}">
    <img src="{{avatar}}">
</app-application-card-avatar>
<div class="content">
    <div class="title">{{title}}</div>
    <div class="description"
         [matTooltip]="description"
         [ellipsis-content]="description"
         matTooltipClass="tooltip"
         ellipsis>
    </div>
</div>
<app-selection-indicator *ngIf="selectable"
                         [selected]="selected">
</app-selection-indicator>
