import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  TemplateRef,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-step-actions',
  templateUrl: './step-actions.component.html',
  styleUrls: ['./step-actions.component.scss']
})
export class StepActionsComponent implements AfterContentChecked {
  @ViewChild(TemplateRef) template: TemplateRef<any>;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }
}
