<ng-container *ngIf="selectedApp$ | async as app; else loadingSpinner">
  <section id="title">
    <p class="name">{{ 'pages.estimate.applications.' + app.key + '.title' | translate }}</p>
  </section>

  <section id="concept">
    <h2 class="title">{{ 'general.texts.concept' | translate }}</h2>
    <p class="description">{{ 'pages.estimate.applications.' + app.key + '.concept' | translate }}</p>
  </section>

  <section id="construct">
    <h2 class="title">{{ 'general.texts.construct' | translate }}</h2>
    <p class="description">{{
        'pages.estimate.applications.' + app.key +
        '.construct' | translate
      }}</p>
    <div class="modules">
      <ng-container *ngFor="let module of app.modules  ; let index = index">
        <app-circle-card flavor="module"
                         [fixed]="isWeb"
                         (click)="onModuleClick(module)"
                         name="{{('pages.estimate.modules.'+ module.key + '.name') | translate}}"
                         content="assets/icons/modules/module_{{module.key}}_icon.svg"></app-circle-card>
      </ng-container>
    </div>
  </section>

  <section id="costs">
    <h2 class="title">{{ 'general.texts.costs' | translate }}</h2>
    <p class="team"
       [innerHTML]="'pages.estimate.costs.structure' | translate: {name: app.key, teamSize: team.size, fixedDuration: computeFixedDuration(app.cost), duration: computeVariableDuration(app.cost), fixedCost:computeFixedCost(app.cost), monthlyCost: computeMonthlyCost(app.cost)}"></p>
    <div class="cards">
      <app-circle-card type="text"
                       flavor="duration"
                       [fixed]="isWeb"
                       name="{{'general.texts.months' | translate}}"
                       content="{{computeTotalDuration(app.cost)}}">
      </app-circle-card>
      <app-circle-card flavor="team"
                       [fixed]="isWeb"
                       name="{{'general.texts.team.size' | translate}}"
                       content="assets/icons/team/team_{{team.size}}.svg">
      </app-circle-card>
      <app-circle-card type="text"
                       flavor="cost"
                       [fixed]="isWeb"
                       name="{{'general.texts.costs' | translate}}"
                       [content]="computeCost(app.cost.total.total)">
      </app-circle-card>
    </div>
  </section>

  <section id="gantt">
    <h2 class="title">{{ 'general.texts.timeline' | translate }}</h2>
    <div class="gantt-container">
      <app-gantt-chart [title]="title"
                     [columnNames]="columnNames"
                     [data]="moduleChartData"
                     [options]="options">
      </app-gantt-chart>
    </div>
  </section>
</ng-container>


<ng-template #loadingSpinner>
  <app-loading-spinner></app-loading-spinner>
</ng-template>



