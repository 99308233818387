import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  TemplateRef,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.scss']
})
export class StepComponent implements AfterContentChecked {
  @ViewChild(TemplateRef) template: TemplateRef<any>;

  @Input() label = '';

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngAfterContentChecked() {
    this.changeDetector.detectChanges();
  }
}
