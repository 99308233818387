<div class="chip-container" [class.visible]="selectedValues?.length > 0">
  <div class="chip"
       [matTooltip]="value"
       matTooltipClass="tooltip"
       *ngFor="let value of selectedValues; let i = index">
    <span class="value"
          ellipsis>{{value}}</span>
    <button class="icon"
            mat-icon-button
            (click)="onRemove(i)">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<mat-form-field>
  <input #nativeElem
         matInput
         [value]="inputValue"
         [type]="type"
         [formControl]="formControl"
         [placeholder]="label"
         (input)="onChange($event)"
         (focusout)="onKeydownEnter($event)"
         (keydown.enter)="onKeydownEnter($event)"
         [required]="required"/>
  <mat-icon matSuffix (click)="onAddValue()">add</mat-icon>
</mat-form-field>
<app-form-error-list
    *ngIf="formControl?.invalid && formControl?.touched"
    [errorMap]="formControl?.errors">
</app-form-error-list>
