import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EstimateComponent } from './estimate.component';
import { ApplicationDetailsCardComponent, CustomAppCardComponent } from "./components";
import { LanguageGuard } from 'libs/ngnizer/ux/src/lib/guards/language.guard';

const estimateRoutes: Routes = [
  {
    path: ':lang',
    component: EstimateComponent,
    canActivate: [LanguageGuard],  // Ensure language is valid before navigating
    children: [
      { path: 'app/custom', component: CustomAppCardComponent },
      { path: 'app/:id', component: ApplicationDetailsCardComponent },
      { path: '**', redirectTo: 'app/custom', pathMatch: 'full' }
    ]
  },
  { path: '', redirectTo: 'en', pathMatch: 'full' },  // Redirect to default language if no path is specified
  { path: '**', redirectTo: 'en', pathMatch: 'full' }  // Redirect unknown paths to 'en'
];

@NgModule({
  imports: [RouterModule.forChild(estimateRoutes)],
  exports: [RouterModule],
  providers: []
})
export class EstimateRoutingModule { }
