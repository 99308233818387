import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChipsModel } from './input-chips/chips.model';
import { ArrayValidators } from '../../utils/array-validators';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss']
})
export class InputsComponent {

  inputsForm: FormGroup;

  inputWidth = '100%';

  personnelChips = [
    new ChipsModel(
      {
        id: 1,
        name: 'Charles Darwin',
        img: 'https://www.nation.com.pk/digital_images/medium/2022-02-06/charles-darwin-stole-his-theory-of-evolution-from-fellow-naturalist-new-book-claims-1644140860-7974.jpg'
      }),
    new ChipsModel(
      {
        id: 2,
        name: 'Adam Smith',
        img: 'https://static01.nyt.com/images/2017/09/18/business/18db-bookentry1/18db-bookentry1-superJumbo.jpg'
      }),
    new ChipsModel({
      id: 3,
      name: 'Daniel Khaneman',
      img: 'https://www.nobelprize.org/images/kahneman-13673-landscape-medium.jpg'
    }),
    new ChipsModel({
      id: 4,
      name: 'George P. Boole',
      img: 'https://cdn.britannica.com/68/6768-050-A7F2D15C/engraving-George-Boole.jpg'
    })
  ];

  options = [
    {
      name: 'Boole',
      value: 'Boole',
      selected: false,
      id: 1
    },
    {
      name: 'Darwin',
      value: 'Darwin',
      selected: false,
      id: 2
    }, {
      name: 'Smith',
      value: 'Smith',
      false: false,
      id: 3
    }, {
      name: 'Khaneman',
      value: 'Khaneman',
      selected: false,
      id: 4,
      color: 'accent'
    }
  ]

  constructor(private fb: FormBuilder) {
    this.inputsForm = this.fb.group({
      text: ['', [Validators.required, Validators.max(50)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      number: ['', [Validators.required, Validators.min(6), Validators.max(8)]],
      multi: [[], [ArrayValidators.maxLength(2)]],
      chips: [[], [Validators.required]],
      dropdown: ['', [Validators.required]],
      radio: [this.options, [ArrayValidators.minSelected(1)]],
      switch: [{value: false, disabled: false}, []],
      date: [null, [Validators.required]],
      textarea: ['', [Validators.required, Validators.minLength(20)]],
    });
  }


  control(controlName: string) {
    return this.inputsForm.get(controlName) as FormControl;
  }
}
