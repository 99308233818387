import {Component, ElementRef, Renderer2} from '@angular/core';

@Component({
  selector: 'app-surface-card',
  templateUrl: './surface.component.html',
  styleUrls: ['./surface.component.scss']
})
export class SurfaceComponent {

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

}
