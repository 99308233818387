export class Team {
  public size: "small" | "medium" | "large";
  public velocity: number;
  public cost: number;
  public monthlyCost: number;

  constructor(team: any = {}) {
    this.size = team?.size || null;
    this.velocity = team?.velocity || 0;
    this.cost = team?.cost || 0;
    this.monthlyCost = team?.monthlyCost || 0;
  }
}
