import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss']
})
export class FormSectionComponent implements OnInit, OnDestroy {

  @Input() isMaterial = false;
  @Input() form: FormGroup;
  @Input() title = '';
  @Input() selectionLimit: number;


  private formSubscription: Subscription;
  private lastSelectedFeatures: any[] = [];

  private static mapFeatureKeys(sectionValue: any) {
    return Object.keys(sectionValue)
    .filter(featureKey => sectionValue[featureKey]);
  }

  constructor() {
  }

  ngOnInit(): void {
    this.initFormSectionListener();
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  private initFormSectionListener() {
    this.formSubscription = this.form.valueChanges.subscribe((sectionValue) => {
      const selectedFeatures = FormSectionComponent.mapFeatureKeys(sectionValue);

      if (selectedFeatures.length > this.selectionLimit) {
        const featuresToPatch = Object.fromEntries(
          this.lastSelectedFeatures
          .map((featureKey) => [featureKey, false])
        );
        this.form.patchValue(featuresToPatch, {emitEvent: false});
      }

      this.lastSelectedFeatures = FormSectionComponent.mapFeatureKeys(this.form.value);
    });
  }
}
