<label *ngIf="mode === 'rectangular'" class="name">{{name | uppercase}}</label>
<label *ngIf="mode === 'circular'" class="name">{{name}}</label>
<img class="icon"
     src="{{icon}}"
     matTooltipClass="tooltip"
     [matTooltip]="description"
     [matTooltipHideDelay]="20"
     alt="icon"/>
<input type="hidden" [(ngModel)]="selected">

