import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-card-avatar',
  templateUrl: './card-avatar.component.html',
  styleUrls: ['./card-avatar.component.scss']
})
export class CardAvatarComponent implements AfterViewInit {
  @ContentChild(forwardRef(() => 'content'), {static: false}) content!: TemplateRef<any>;
  @ViewChild('cover') cover!: ElementRef;

  @Input() type: 'color' | 'image' = 'color';

  @Input() background = '';

  constructor(private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {
    const background = this.type === 'color' ? this.background : `linear-gradient(0deg, rgba(40, 102, 118, 0.3), rgba(40, 102, 118, 0.3)), url(${this.background}) no-repeat center`
    this.renderer.setStyle(this.cover.nativeElement, 'background', background);
  }
}
