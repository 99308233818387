import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {Actions} from '@ngrx/effects';
import {NotificationService} from '@ngnizer/ux';


@Injectable()
export class AppEffects {

  constructor(private notificationService: NotificationService,
              private translateService: TranslateService,
              private actions$: Actions,
              private router: Router) {
  }

}
