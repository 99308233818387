import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Theme } from './models/theme.model';
import { UXActions, UXReducer } from './state/';
import { UXState } from './state/ux.reducer';

@UntilDestroy()
@Component({
  selector: 'app-ux',
  templateUrl: './ux.component.html',
  styleUrls: ['./ux.component.scss']
})
export class UxComponent {

  tabs = [
    {
      label: this.translate.instant('ux.buttons.title'),
      path: 'buttons',
      active: false,
      icon: 'gamepad',
      visible: true,
      svgIcon: ''
    },
    {
      label: this.translate.instant('ux.inputs.title'),
      path: 'inputs',
      active: false,
      icon: 'padding',
      visible: true,
      svgIcon: ''
    },
    {
      label: this.translate.instant('ux.content.title'),
      path: 'content',
      active: false,
      icon: 'image',
      visible: true,
      svgIcon: ''
    }
  ];
  themes: any[] = [
    {
      icon: 'wb_sunny',
      label: 'Light',
      value: Theme.Light
    },
    {
      icon: 'dark_mode',
      label: 'Dark',
      value: Theme.Dark
    }
  ];

  home = 'how';

  theme$: Observable<Theme> | null;

  constructor(private store: Store<UXState>,
              private translate: TranslateService) {
    this.theme$ = this.store.pipe(select(UXReducer.getTheme));
  }

  onOptionClick(option: any) {
    switch (option) {
      case Theme.Light:
        this.store.dispatch(UXActions.selectTheme({
          theme: Theme.Light
        }));
        break;
      case Theme.Dark:
        this.store.dispatch(UXActions.selectTheme({
          theme: Theme.Dark
        }));
        break;
    }
  }
}
