import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private toastr: ToastrService) {
    }

    success(title: string, message: string) {
        this.toastr.success(message, title, {timeOut: 5000});
    }

    info(title: string, message: string) {
        this.toastr.info(message, title, {timeOut: 5000});
    }

    error(title: string, message: string) {
        this.toastr.error(message, title, {timeOut: 5000});
    }
}
