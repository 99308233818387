import { NgModule } from '@angular/core';
import { NgxImgModule } from 'ngx-img';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { TranslateModule } from '@ngx-translate/core';
import { EllipsisModule } from 'ngx-ellipsis';

@NgModule({
  declarations: [],
  imports: [
    NgxImgModule,
    TourMatMenuModule,
    TranslateModule,
    EllipsisModule,
  ],
  exports: [
    NgxImgModule,
    TourMatMenuModule,
    TranslateModule,
    EllipsisModule,
  ],
  providers: []
})
export class NGXLibraryModule {
}
