<app-material [inDialog]="true">
  <app-material-header>
    {{data.title}}
    <mat-icon *ngIf="data.icon">{{data.icon}}</mat-icon>
  </app-material-header>
  <app-material-body>
    <p>{{data.message}}</p>
  </app-material-body>
  <app-material-footer>
    <ng-container *ngFor="let btn of data.btns">
      <app-button *ngIf="btn.isCancel" (appClick)="onCancel()">{{btn.text | uppercase}}</app-button>
      <app-button *ngIf="btn.isSubmit" (appClick)="onSubmit()">{{btn.text | uppercase}}</app-button>
    </ng-container>
  </app-material-footer>
</app-material>
