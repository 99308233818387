<ng-container *ngIf="hasLinks; else tabGroup">
  <nav mat-tab-nav-bar
       mat-stretch-tabs>
    <ng-container *ngFor="let link of tabs">
      <a mat-tab-link
         *ngIf="link.visible"
         [routerLink]="link.path"
         [state]="link.state"
         routerLinkActive #rla="routerLinkActive"
         (click)="onTabChanged(link)"
         [active]="link.active || rla.isActive">
        {{link.label}}
      </a>
    </ng-container>
  </nav>
</ng-container>

<ng-template #tabGroup>
  <mat-tab-group mat-align-tabs="center"
                 [selectedIndex]="selectedTabIndex"
                 dynamicHeight
                 (selectedTabChange)="onTabChanged($event)">
    <mat-tab *ngFor="let tabComponent of tabComponents; let index = index"
             [label]="tabs[index]">
      <ng-template matTabContent>
        <ng-container *ngTemplateOutlet="tabComponent.content; context: {content: tabComponent}"></ng-container>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-template>
