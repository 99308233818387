import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-selection-indicator',
  templateUrl: './selection-indicator.component.html',
  styleUrls: ['./selection-indicator.component.scss']
})
export class SelectionIndicatorComponent {
  @HostBinding('class.selected') @Input() selected = false;

  @Output() appClick = new EventEmitter<any>();

  @HostListener('click', ['$event'])
  public toggle(event: PointerEvent): void {
    this.appClick.emit(this.selected);
  }
}
