import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageGuard implements CanActivate {
  private validLanguages = ['en', 'ro'];  // Define supported languages

  constructor(private translate: TranslateService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const language = route.paramMap.get('lang');

    // If language is valid, use it and allow navigation
    if (this.validLanguages.includes(language)) {
      this.translate.use(language);
      return true;
    } else {
      // If no valid language, redirect to default language (e.g., 'en')
      this.router.navigate(['en']);
      return false;
    }
  }
}
