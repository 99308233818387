import {Component, Inject} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA} from "@angular/material/bottom-sheet";
import {Module} from "@ngnizer/estimate";
import {select, Store} from "@ngrx/store";
import * as estimateActions from "../../state/estimate.actions";
import {filter} from "rxjs/operators";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Observable} from 'rxjs/internal/Observable';
import * as estimateReducers from "../../state/estimate.reducer";

@UntilDestroy()
@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent {

  public selectedModule$: Observable<Module>;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private store: Store<any>) {

    // Subscribe to module
    this.selectedModule$ = this.store.pipe(select(estimateReducers.getSelectedModule));


    if (data.custom) {
      // Load module as individual module
      this.store.dispatch(estimateActions.loadModule({key: data.module.key}));
    } else {
      // Load module as module that is part of an app (which may be different under certain circumstances)
      this.store.dispatch(estimateActions.loadApplicationModule({
        appKey: data.app,
        moduleKey: data.module.key
      }));
    }

    this.selectedModule$.pipe(filter((app) => !!app), untilDestroyed(this))
      .subscribe((module: Module) => {
        console.log(module);
      });
    // Load current module

  }

}
