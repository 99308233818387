import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  Renderer2
} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CardAvatarComponent} from './card-avatar/card-avatar.component';
import {CardActionsComponent} from './card-actions/card-actions.component';
import {CardContentComponent} from './card-content/card-content.component';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  animations: [
    trigger('easeInOut', [
      state('show', style({
        opacity: 1,
      })),
      state('hide', style({
        opacity: 0,
      })),
      transition('show => hide', animate('300ms ease-out')),
      transition('hide => show', animate('300ms ease-in'))
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CardComponent,
      multi: true
    }
  ],
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements ControlValueAccessor {
  @ContentChild(CardAvatarComponent, {static: false}) avatar!: CardAvatarComponent;
  @ContentChild(CardContentComponent, {static: false}) content!: CardContentComponent;
  @ContentChild(CardActionsComponent, {static: false}) footer!: CardActionsComponent;

  @HostBinding('class.selected') @Input() get selected() {
    return this.selectable && this._selected;
  }

  set selected(selected) {
    this._selected = selected;
  }

  @Input() value: any;
  @Input() selectable = false;

  @HostBinding('class.horizontal') @Input() horizontal: boolean;


  @Output() appClick = new EventEmitter<any>();

  // tslint:disable-next-line:variable-name
  _selected = false;

  private onChangeCallback: ((_: any) => {}) | undefined;
  private onTouchedCallback: ((_: any) => {}) | undefined;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

  @HostListener('click', ['$event'])
  public toggle(event: PointerEvent): void {
    this.appClick.emit(this.value);
  }


  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: boolean): void {
    this.selected = obj;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef, 'disabled', '');
  }
}
