import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

/**
 * Custom implementation of a textarea. This textarea is disabled by default, and it is re-enabled
 * upon clicking it. It becomes disabled again once it is focused out.
 */

@Component({
  selector: 'app-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputTextareaComponent,
      multi: true
    }
  ],
})
export class InputTextareaComponent implements ControlValueAccessor {


  @HostBinding('class.editable') get isEditable() {
    return this.editable;
  }

  @HostBinding('class.reactive') get isReactive() {
    return this.reactive;
  }


  get value() {
    return this.inputValue;
  }

  set value(value) {
    this.inputValue = value;

    this.onChangeCallback(value);
    this.inputChange.emit(value);
  }

  @ViewChild('textarea', {static: false}) textArea: ElementRef;

  @Input() formControl: FormControl;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() maxLength = 100;
  @Input() showValidations = false;
  @Input() isReadOnly = false;
  @Input() isDisabled = false;
  @Input() enableOnClickMode = false;
  @Input() reactive = true;
  @Input() minRows = 1;
  @Input() maxRows = 5;

  @Output() inputChange = new EventEmitter<any>();
  @Output() toggleDisable = new EventEmitter<any>();

  editable = false;
  hovered = false;

  private onChangeCallback: (_: any) => {};
  private onTouchedCallback: (_: any) => {};

  private inputValue = '';

  @HostListener('blur') onBlur() {
    this.editable = false;
  }


  @HostListener('click') onClick() {
    this.editable = true;
  }

  writeValue(value: any) {
    this.inputValue = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  enable() {
    if (this.enableOnClickMode) {
      this.formControl.enable();
      this.textArea.nativeElement.focus();
      this.toggleDisable.emit({type: 'enable', value: this.formControl.value});
    }
  }

  disable() {
    if (this.enableOnClickMode) {
      this.formControl.disable();
      this.toggleDisable.emit({type: 'disable', value: this.formControl.value});
    }
  }

  onMouseEnter() {
    this.hovered = true;
  }

  onMouseExit() {
    this.hovered = false;
  }

  get isValid() {
    return this.formControl && this.formControl.valid && this.formControl.touched;
  }

  get showErrors() {
    return this.showValidations || (this.formControl?.invalid && this.formControl?.touched);
  }

}
