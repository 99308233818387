<div class="circle" *ngIf="type=='image'">
  <img class="image" [src]="content" alt=""/>
  <p class="name">{{ name }}</p>
</div>
<div class="circle" *ngIf="type=='text'">
  <p *ngIf="flavor != 'duration'" class="avatar">{{ content }}</p>
  <img *ngIf="flavor == 'duration'" class="image"
       [src]="content" alt=""/>
  <p class="name">{{ name }}</p>
</div>
