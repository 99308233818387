import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import * as EstimateReducer from './state/estimate.reducer';

import { EstimateEffects } from './state/estimate.effects';

import {
  ApplicationCardAvatarComponent,
  ApplicationCardComponent,
  ApplicationDetailsCardComponent,
  CustomAppCardComponent,
  FeatureCard,
  FormSectionComponent,
  FormSectionFeatureCardComponent,
  TotalCostEstimateComponent
} from './components';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { EffectsModule } from '@ngrx/effects';
import { EstimateComponent } from './estimate.component';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { UxModule } from '@ngnizer/ux';
import { EllipsisModule } from 'ngx-ellipsis';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { CircleCardComponent } from './components/circle-card/circle-card.component';
import { EstimateNotificationEffects } from './estimate.notifications';

@NgModule({
  declarations: [
    EstimateComponent,
    FormSectionFeatureCardComponent,
    FormSectionComponent,
    TotalCostEstimateComponent,
    ApplicationCardComponent,
    ApplicationCardAvatarComponent,
    BottomSheetComponent,
    ApplicationDetailsCardComponent,
    CircleCardComponent,
    FeatureCard,
    CustomAppCardComponent
  ],
  imports: [
    UxModule,
    TranslateModule,
    StoreModule.forFeature(EstimateReducer.estimateFeatureKey, EstimateReducer.reducer),
    EffectsModule.forFeature([EstimateEffects, EstimateNotificationEffects]),
    TourMatMenuModule,
    EllipsisModule
  ],
  exports: [
    FormSectionComponent
  ]
})
export class EstimateModule {

  public static forRoot(environment?: any): ModuleWithProviders<EstimateModule> {

    return {
      ngModule: EstimateModule,
      providers: [
        {
          provide: 'environment',
          useValue: environment
        }
      ]
    };
  }
}
