import {
  Component,
  ElementRef, EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-full-row-section',
  templateUrl: './full-row-section.component.html',
  styleUrls: ['./full-row-section.component.scss'],
  animations: [
    trigger('scrollLeftEntranceAnimation', [
      state('show', style({
        opacity: 1,
        transform: 'translateX(0)'
      })),
      state('hide', style({
        opacity: 0,
        transform: 'translateX(-100%)'
      })),
      state('seen', style({
        opacity: 1,
      })),
      transition('show => hide', animate('300ms ease-out')),
      transition('hide => show', animate('300ms ease-in'))
    ]),
    trigger('scrollRightEntranceAnimation', [
      state('show', style({
        opacity: 1,
        transform: 'translateX(0%)'
      })),
      state('hide', style({
        opacity: 0,
        transform: 'translateX(+100%)'
      })),
      state('seen', style({
        opacity: 1,
      })),
      transition('show => hide', animate('300ms ease-out')),
      transition('hide => show', animate('300ms ease-in'))
    ]),
    trigger('scrollBottomEntranceAnimation', [
      state('show', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      state('hide', style({
        opacity: 0,
        transform: 'translateY(+100%)'
      })),
      state('seen', style({
        opacity: 1,
      })),
      transition('show => hide', animate('300ms ease-out')),
      transition('hide => show', animate('300ms ease-in'))
    ])
  ]
})
export class FullRowSectionComponent implements OnInit, OnChanges {
  @Input() index: any;
  @Input() image: string;
  @Input() heading: string;
  @Input() text: string;
  @Input() ctaText = '';
  @Input() orientation: 's-row' | 's-reverse' | 's-column' = 's-row';
  @Input() parentScroll = 0;
  @Input() entrance: 'none' | 'bottom' | 'top' | 'left' | 'right' = 'none';
  @Input() percentageToArea = .20;
  @Input() hasCustomText = false;
  @Input() ctaId = '';

  @Output() cta = new EventEmitter<any>();

  @HostBinding('class.onsafari') safari = false;
  @HostBinding('class.s-row') sRow = false;
  @HostBinding('class.s-reverse') sReverse = false;
  @HostBinding('class.s-column') sColumn = false;

  bottomEntrance = 'hide';
  leftEntrance = 'hide';
  rightEntrance = 'hide';

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.safari = !!this.isSafariMobile();
  }


  isSafariMobile() {
    const userAgent = window.navigator.userAgent;
    return userAgent.match(/iPad/i) || userAgent.match(/iPhone/i);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const orientation = 'orientation';
    if (changes[orientation]) {
      switch (this.orientation) {
        case 's-column':
          this.sColumn = true;
          break;
        case 's-reverse':
          this.sReverse = true;
          break;
        case 's-row':
          this.sRow = true;
          break;
        default:
          break;
      }
    }

    const parentScroll = 'parentScroll';
    if (changes[parentScroll]) {
      this.doScrollAnimation(this.elementRef.nativeElement);
    }
  }

  @HostListener('resize', ['$event'])
  onResize(event: any) {
    this.doScrollAnimation(this.elementRef.nativeElement);
  }

  ctaClick() {
    this.cta.emit();
  }

  private doScrollAnimation(element: HTMLElement) {
    const isScrolledInView = this.isScrolledTo(element);
    switch (this.entrance) {
      case 'none':
        this.bottomEntrance = 'seen';
        this.leftEntrance = 'seen';
        this.rightEntrance = 'seen';
        break;
      case 'bottom':
        this.bottomEntrance = isScrolledInView ? 'show' : 'hide';
        break;
      case 'left':
        this.leftEntrance = isScrolledInView ? 'show' : 'hide';
        break;
      case 'right':
        this.rightEntrance = isScrolledInView ? 'show' : 'hide';
        break;
    }
  }

  private isScrolledTo(element: HTMLElement): boolean {
    const childHeight = (element.children[0] as HTMLElement).offsetHeight;
    let visible = false;
    if (element.offsetTop === 0) {
      visible = true;
    } else if (this.parentScroll >= (element.offsetTop - childHeight * this.percentageToArea)) {
      visible = true;
    }

    return visible;
  }
}
