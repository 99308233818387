import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ChartType } from 'angular-google-charts';
import { ChartColumn, ChartItem } from '../../../models/chart-data.model';
import { Theme } from '../../../models/theme.model';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-gantt-chart',
  templateUrl: './gantt-chart.component.html',
  styleUrls: ['./gantt-chart.component.scss'],
  host: {
    'class': 'app-gantt-chart'
  }
})
export class GanttChartComponent implements OnInit {
  @Input() title = '';
  @Input() type: ChartType = ChartType.Gantt;
  @Input() columnNames: ChartColumn[] = [];
  @Input() data: ChartItem[] = [];
  @Input() options: any;

  chartData: any[] = [];
  theme$: Observable<Theme>;
  currentTheme: Theme = Theme.Light;
  themeSubscription: Subscription;

  constructor(
    private el: ElementRef) {
  }

  ngOnInit(): void {
    this.chartData = this.data.map(item => {
      return item.data;
    });
  }
}
