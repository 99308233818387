import { Observable, throwError as observableThrowError } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { NGXLogger } from 'ngx-logger';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthRoutes } from '../routes/routes';


/**
 * Interceptor for handling errors.
 */

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
  private readonly X_CORRELATION_ID = 'X-Correlation-ID';
  private translateService: TranslateService;
  private router: Router;
  private logger: NGXLogger;

  constructor(private injector: Injector) {
    // what the fuck angular
    setTimeout(() => {
      this.translateService = this.injector.get(TranslateService);
      this.router = this.injector.get(Router);
      this.logger = this.injector.get(NGXLogger);
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError((res: HttpErrorResponse) => {
      switch (res.status) {
        case 400:
        case 403:
        case 409:
        case 500:
          if (res.error && res.error.message) {
            const errorMessage = this.translateService.instant(res.error.message);
            this.logger.error(errorMessage);
            // TODO should toast error;

          }
          const correlationId = req.headers.get(this.X_CORRELATION_ID);
          if (correlationId) {
            this.logger.error('Please contact the administrator and use this ID: ' + correlationId);
            const errorMessage =
                this.translateService.instant('error.correlationId', {correlationId});
            // TODO should toast error;
          }
          break;
        case 401:
          this.logger.error('Unauthorized ', res);
          if (res.error && res.error.message) {
            // TODO should toast this.translateService.instant(res.error.message);
          } else {
            this.router.navigate([AuthRoutes.Login.url]).then();
          }
          break;
        case 404:
          break;
        default:
          if (res && res.status) {
            return observableThrowError(res);
          }
      }

      return observableThrowError(res);
    }));
  }
}
