import { NgModule } from '@angular/core';
import { NgxImgModule } from 'ngx-img';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { TranslateModule } from '@ngx-translate/core';
import { EllipsisModule } from 'ngx-ellipsis';
import { GoogleChartsModule } from 'angular-google-charts';

@NgModule({
  declarations: [],
  imports: [
    NgxImgModule,
    TourMatMenuModule,
    TranslateModule,
    EllipsisModule,
    GoogleChartsModule
  ],
  exports: [
    NgxImgModule,
    TourMatMenuModule,
    TranslateModule,
    EllipsisModule,
    GoogleChartsModule
  ],
  providers: []
})
export class NGXLibraryModule {
}
