import {Component, ElementRef, Input, Renderer2} from '@angular/core';
import {TreeNode} from "primeng/api";

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent {

  @Input() data: TreeNode[];

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  }

}
