import { Component, HostListener, OnInit } from '@angular/core';

import { Router } from '@angular/router';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  scroll: any;

  constructor(private router: Router) {
  }

  @HostListener('scroll', ['$event'])
  onScroll($event: Event): void {
    this.scroll = ($event.target as Element).scrollTop;
  }

  ngOnInit(): void {
  }

  onCTA() {
    this.router.navigate(['estimate']);
  }
}
