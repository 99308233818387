import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Theme } from '../../../models/theme.model';

@Component({
  selector: 'app-appbar',
  templateUrl: './appbar.component.html',
  styleUrls: ['./appbar.component.scss']
})
export class AppbarComponent implements OnInit {

  @Input() user: any = null;
  @Input() tabs: any[] = [];
  @Input() theme: Theme | null | undefined;
  @Input() themes: any[] = [];
  @Input() languages: any[] = [];
  @Input() language = 'en';
  @Input() home = '';
  @Input() logo = '';
  @Input() linkIconOrder: 'icon-first' | 'icon-last' = 'icon-first';

  @Output() menuClick = new EventEmitter<any>();
  @Output() logout = new EventEmitter<any>();
  @Output() themeChange = new EventEmitter<any>();
  @Output() languageChange = new EventEmitter<any>();

  @HostBinding('class.compact') @Input() compact = false;

  languageKey = 'user-language';

  public navLinks: any[] = [];

  constructor(private translationService: TranslateService,
              private router: Router,
              private route: ActivatedRoute) {
  }


  ngOnInit() {
  }

  onLogout() {
    this.logout.emit();
  }

  translate(language: string) {
    this.translationService.use(language);
    localStorage.setItem(this.languageKey, language);
  }

  clickMenu() {
    this.menuClick.emit();
  }

  onOptionClick(value: any) {
    this.themeChange.emit(value);
  }

  onOptionToggle() {
    if (this.theme === Theme.Dark) {
      this.themeChange.emit(Theme.Light)
    } else {
      this.themeChange.emit(Theme.Dark);
    }
  }

  isOptionSelected(value: any) {
    return this.theme === value;
  }

  computeIcon(theme: Theme) {
    return theme === Theme.Dark ? 'wb_sunny' : 'dark_mode';
  }

  onLanguageChange(language: any) {
    this.language = language;
    this.languageChange.emit(language);
  }
}
