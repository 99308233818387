<mat-sidenav-container class="example-container">
  <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over" opened="false">
    <div>
      <a mat-button routerLink="/about-us">About us</a>
      <a mat-button routerLink="/prices">Prices</a>
      <a mat-button routerLink="/start-page">Start page</a>
      <a mat-button routerLink="/offer">Offer</a>
      <a mat-button routerLink="/contact">Contact</a>
    </div>
  </mat-sidenav>
  <mat-sidenav-content fxFlexFill>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
