<mat-stepper [orientation]="orientation"
             [linear]="linear"
             [labelPosition]="headerLabelPosition"
             [title]="'Tot'"
             [selectedIndex]="0"
             (selectionChange)="selectionChangeStepper($event)"
             #stepper>
  <ng-template matStepperIcon="edit" label>
    <mat-icon>done</mat-icon>
  </ng-template>
  <mat-step *ngFor="let step of steps; let i = index"
            [ngClass]="{'active': i === 0}">
    <div class="step-label">
      <ng-template matStepLabel>
        {{step.label}}
      </ng-template>
    </div>
    <div class="step-content">
      <ng-container *ngTemplateOutlet="step.template"></ng-container>
    </div>
  </mat-step>
</mat-stepper>
<div class="step-actions">
  <ng-container *ngTemplateOutlet="actions.template"></ng-container>
</div>
