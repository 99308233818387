import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-estimate-group',
  templateUrl: './estimate-group.component.html',
  styleUrls: ['./estimate-group.component.scss']
})
export class EstimateGroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
