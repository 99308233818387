<ng-container [ngSwitch]="type">
  <mat-form-field class="date-field" *ngSwitchCase="'date'">
    <input #nativeElem
           matInput
           [name]="id"
           [formControl]="formControl"
           [placeholder]="label"
           [readonly]="isReadonly"
           (focus)="picker.open()"
           (click)="picker.open()"
           [matDatepicker]="picker"/>
    <mat-icon class="input-icon"
              [class.material-icons-outlined]="!isValid"
              [class.material-icons]="isValid"
              matSuffix>today
    </mat-icon>
    <mat-datepicker #picker [startView]="startView"></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="text-field" *ngSwitchCase="'text'">
    <input #nativeElem
           matInput
           [name]="id"
           [type]="type"
           [formControl]="formControl"
           [placeholder]="label"
           (input)="onChange($event)"
           (blur)="onBlur()"
           [minLength]="minLength"
           [maxLength]="maxLength"
           (keypress)="forceKeyPressUpperCase($event)"
           [readonly]="isReadonly"
           [required]="required"/>
    <mat-icon *ngIf="reactive" class="editIcon">edit</mat-icon>
    <mat-icon *ngIf="prefixIcon" matPrefix>{{prefixIcon}}</mat-icon>
    <span class="input-icon text-icon"
          matSuffix>{{formControl?.value?.length}}
    </span>
  </mat-form-field>
  <mat-form-field class="email-field" *ngSwitchCase="'email'">
    <input #nativeElem
           matInput
           [name]="id"
           [type]="type"
           [formControl]="formControl"
           [placeholder]="label"
           (input)="onChange($event)"
           (blur)="onBlur()"
           [minLength]="minLength"
           [maxLength]="maxLength"
           (keypress)="forceKeyPressUpperCase($event)"
           [readonly]="isReadonly"
           [required]="required"/>
    <mat-icon *ngIf="reactive" class="editIcon">edit</mat-icon>
    <mat-icon *ngIf="prefixIcon" matPrefix>{{prefixIcon}}</mat-icon>
    <mat-icon class="input-icon"
              [class.material-icons-outlined]="!isValid"
              [class.material-icons]="isValid"
              matSuffix>email
    </mat-icon>
  </mat-form-field>
  <mat-form-field class="password-field" *ngSwitchCase="'password'">
    <input #nativeElem
           matInput
           [name]="id"
           [type]="type"
           [formControl]="formControl"
           [placeholder]="label"
           (input)="onChange($event)"
           (blur)="onBlur()"
           [minLength]="minLength"
           [maxLength]="maxLength"
           (keypress)="forceKeyPressUpperCase($event)"
           [readonly]="isReadonly"
           [required]="required"/>
    <mat-icon *ngIf="reactive" class="editIcon">edit</mat-icon>
    <mat-icon *ngIf="prefixIcon" matPrefix>{{prefixIcon}}</mat-icon>
    <mat-icon class="input-icon"
              [class.material-icons-outlined]="!isValid"
              [class.material-icons]="isValid"
              [style]="{cursor: 'pointer'}"
              (click)="togglePassword()" matSuffix>
      {{reveal ? 'visibility' : 'visibility_off'}}
    </mat-icon>
    <mat-hint *ngIf="hintMessage">{{hintMessage}}</mat-hint>
  </mat-form-field>
  <mat-form-field class="password-field" *ngSwitchCase="''">
    <!-- Used by the password field in order to reveal passwords -->
    <input #nativeElem
           matInput
           [name]="id"
           [type]="type"
           [formControl]="formControl"
           [placeholder]="label"
           (input)="onChange($event)"
           (blur)="onBlur()"
           [minLength]="minLength"
           [maxLength]="maxLength"
           (keypress)="forceKeyPressUpperCase($event)"
           [readonly]="isReadonly"
           [required]="required"/>
    <mat-icon *ngIf="reactive" class="editIcon">edit</mat-icon>
    <mat-icon *ngIf="prefixIcon" matPrefix>{{prefixIcon}}</mat-icon>
    <mat-icon class="input-icon"
              [class.material-icons-outlined]="!isValid"
              [class.material-icons]="isValid"
              [style]="{cursor: 'pointer'}"
              (click)="togglePassword()" matSuffix>
      {{reveal ? 'visibility' : 'visibility_off'}}
    </mat-icon>
    <mat-hint *ngIf="hintMessage">{{hintMessage}}</mat-hint>
  </mat-form-field>
  <mat-form-field class="search-field" *ngSwitchCase="'search'">
    <input #nativeElem
           matInput
           [name]="id"
           [type]="type"
           [formControl]="formControl"
           [placeholder]="label"
           (input)="onChange($event)"
           (blur)="onBlur()"
           [minLength]="minLength"
           [maxLength]="maxLength"
           (keypress)="forceKeyPressUpperCase($event)"
           [readonly]="isReadonly"
           [required]="required"/>
    <mat-icon *ngIf="reactive" class="editIcon">edit</mat-icon>
    <mat-icon *ngIf="prefixIcon" matPrefix>{{prefixIcon}}</mat-icon>
    <mat-icon *ngIf="isSearch" class="search-icon" matSuffix>search</mat-icon>
    <mat-hint *ngIf="hintMessage">{{hintMessage}}</mat-hint>
  </mat-form-field>
  <mat-form-field class="number-field" *ngSwitchCase="'number'">
    <input #nativeElem
           matInput
           [name]="id"
           [type]="type"
           [formControl]="formControl"
           [placeholder]="label"
           (input)="onChange($event)"
           (blur)="onBlur()"
           [minLength]="minLength"
           [maxLength]="maxLength"
           (keypress)="forceKeyPressUpperCase($event)"
           [readonly]="isReadonly"
           [required]="required"/>
    <mat-icon *ngIf="reactive" class="editIcon">edit</mat-icon>
    <mat-icon *ngIf="prefixIcon" matPrefix>{{prefixIcon}}</mat-icon>
    <div matSuffix
         class="step-arrow-wrapper">
      <div (mousedown)="$event.preventDefault()" (click)="stepUp()" class="step-up-arrow"></div>
      <div (mousedown)="$event.preventDefault()" (click)="stepDown()" class="step-down-arrow"></div>
    </div>
    <mat-hint *ngIf="hintMessage">{{hintMessage}}</mat-hint>
  </mat-form-field>

  <mat-form-field *ngSwitchDefault>
    NOP
  </mat-form-field>
</ng-container>
<app-form-error-list
    *ngIf="showErrors"
    [errorMap]="formControl?.errors">
</app-form-error-list>
