import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-acronym',
  templateUrl: './acronym.component.html',
  styleUrls: ['./acronym.component.scss']
})
export class AcronymComponent implements OnInit {

  @Input() letter = '';
  @Input() text = '';
  @Input() description = '';


  constructor() {
  }

  ngOnInit(): void {
  }

}
