import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CheckboxComponent,
      multi: true
    }
  ]
})
export class CheckboxComponent implements ControlValueAccessor, AfterViewChecked {

  @Input()
  set checked(checked: boolean) {
    this.isChecked = checked;
  }

  get checked(): boolean {
    return this.isChecked;
  }

  @Input() formControl: FormControl;
  @Input() isDisabled: boolean;
  @Input() label = '';

  // labelPosition can have two values: 'after' and 'before'
  @Input() labelPosition: 'before' | 'after' = 'before';

  @Input() showValidations = false;

  @Output() changed = new EventEmitter<boolean>();

  private isChecked: boolean;

  private onChangeCallback: (_: any) => {};
  private onTouchedCallback: (_: any) => {};

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  onChange(checkbox: any) {
    this.isChecked = checkbox.checked;
    this.changed.emit(this.checked);
    if (this.onChangeCallback) {
      this.onChangeCallback(this.checked);
    }
    if (this.onTouchedCallback) {
      this.onTouchedCallback(this.checked);
    }
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  writeValue(value: any) {
    this.checked = !!value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  get value() {
    return this.checked;
  }
}
