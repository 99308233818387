import { Component, ContentChild, EventEmitter, HostBinding, Input, Output } from '@angular/core';

import { MaterialBodyComponent, MaterialFooterComponent, MaterialHeaderComponent } from './components';

@Component({
    selector: 'app-material',
    templateUrl: './material.component.html',
    styleUrls: ['./material.component.scss']
})
export class MaterialComponent {
    @ContentChild(MaterialHeaderComponent, {static: false}) header: MaterialHeaderComponent;
    @ContentChild(MaterialBodyComponent, {static: false}) body: MaterialBodyComponent;
    @ContentChild(MaterialFooterComponent, {static: false}) footer: MaterialFooterComponent;

    @Input() hasHeader = true;
    @Input() hasFooter = true;
    @Input() style = {};
    @Input() class = {};
    @Input() tabs: any[];

    @Output() tabChange = new EventEmitter<number>();

    @HostBinding('class.responsive') @Input() responsive = false;

    @HostBinding('class.in-dialog') @Input() inDialog = false;

    @HostBinding('class.is-material') @Input() isMaterial = true;

    @HostBinding('class.only-body') hasNoHeaderOrFooter() {
        return !this.hasHeader && !this.hasFooter;
    }

    tabChanged(tab: any) {
        this.tabChange.emit(tab);
    }

    get hasTabs() {
        return this.tabs && this.tabs.length && this.tabs[0];
    }
}
