import {AbstractControl, FormArray, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class ArrayValidators {

  // max length
  public static maxLength(max: number): ValidatorFn | any {
    return (control: AbstractControl): ValidationErrors | null => {
      const modules = control.value;

      // Check if the array has the maximum required length
      if (!modules || modules.length > max) {
        return {minArrayLength: {requiredLength: max, actualLength: modules ? modules.length : 0}};
      }

      return null; // No errors
    };
  }

  // min length
  public static minLength(min: number): ValidatorFn | any {
    return (control: AbstractControl): ValidationErrors | null => {
      const modules = control.value;

      // Check if the array has the minimum required length
      if (!modules || modules.length < min) {
        return {minArrayLength: {requiredLength: min, actualLength: modules ? modules.length : 0}};
      }

      return null; // No errors
    };
  }

  public static minSelected(min: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if ((control instanceof FormControl)) {
        const value = control.value.filter((cntr: any) => cntr.selected)
        return value === null || value.length < min ? {minArraySelected: true} : null;
      }
      return null;
    }
  }

  // between length
  public static betweenLength(min: number, max: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof FormArray)) return null;
      return control.length < min || control.length > max ? {betweenLength: true} : null;
    }
  }
}
