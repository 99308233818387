import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    scroll: number;

    constructor(private router: Router) {
    }

    @HostListener('scroll', ['$event'])
    onScroll($event: Event): void {
        this.scroll = ($event.target as Element).scrollTop;
    }

    ngOnInit(): void {
    }

    onCTA() {
        this.router.navigate(['estimate']);
    }
}
