import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-header',
  templateUrl: './material-header.component.html',
  styleUrls: ['./material-header.component.scss']
})
export class MaterialHeaderComponent implements OnInit {
  @Input() showHeader = true;

  constructor() {
  }

  ngOnInit() {
  }
}
