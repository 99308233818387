// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: true,

    api: {
        rest: {
            caas: 'http://caasurl:1900'
        },
        ws: {},
        firebase: {
            apiKey: 'AIzaSyBqc61H5fk5lZUSzDt2FYFy1vVVy5V1DHA',
            authDomain: 'appmake-dcbc2.firebaseapp.com',
            projectId: 'appmake-dcbc2',
            storageBucket: 'appmake-dcbc2.appspot.com',
            messagingSenderId: '510784343359',
            appId: '1:510784343359:web:cca4385eff934fe3a4578a',
            measurementId: 'G-YBHN8RN57K'
        }
    },

    cookie: {
        domain: 'localhost',
        cookiePolicyURL: 'http://localhost:4200/gdpr'
    },

    defaultLanguage: 'en',

    toasts: {
        duration: 5000
    },

    currency: 'EUR',
    storyPointValue: 2.5,
    smallMultiplier: 0.8,
    baseMultiplier: 1,
    highMultiplier: 1.2,
};
