import { Component, HostListener, Input, OnInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  animations: [
    trigger('expandCollapse', [
      // expandCollapse animation
      state('expanded', style({
        minWidth: '320px'
      })),
      state('collapsed', style({
        minWidth: '80px'
      })),
      transition('expanded <=> collapsed', [
        animate('0.2s ease-in-out')
      ])
    ])
  ]
})
export class SidenavComponent implements OnInit {
  private static readonly MAX_MOBILE_WIDTH = 960;

  @Input() tabs: any[] | undefined;

  drawerExpanded = true;

  isMobile = false;

  constructor() {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.expandDrawerBasedOnSize(event.target.innerWidth);
  }

  ngOnInit(): void {
    this.expandDrawerBasedOnSize(window.innerWidth);
  }

  toggleDrawer() {
    if (this.isMobile) {
      return;
    }
    this.drawerExpanded = !this.drawerExpanded;
  }

  private expandDrawerBasedOnSize(innerWidth: number) {
    this.isMobile = innerWidth <= SidenavComponent.MAX_MOBILE_WIDTH;
    this.drawerExpanded = !this.isMobile;
  }
}
