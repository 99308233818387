import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-input-dropdown-autocomplete',
  templateUrl: './input-dropdown-autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputDropdownAutocompleteComponent,
      multi: true
    }
  ],
  styleUrls: ['./input-dropdown-autocomplete.component.scss']
})
export class InputDropdownAutocompleteComponent implements OnInit, OnChanges, ControlValueAccessor {
  @ViewChild('autocompleteInput', {static: false}) autocompleteInput: ElementRef;

  @Input() label = '';

  @Input()
  set value(value: any) {
    this.selectedValue = value;
  }

  get value() {
    return this.selectedValue;
  }

  get inputValue() {
    if (this.withObjects && this.value && typeof this.value !== 'string') {
      return this.value[this.displayField];
    } else {
      return this.value;
    }
  }

  @Input() formControl: FormControl;
  @Input() options: any[] = [];
  @Input() displayField = 'name';
  @Input() valueField = 'id';
  @Input() withObjects = true;

  @Output() optionSelected = new EventEmitter<any>();

  selectedValue: string = '';

  filteredOptions: any[];

  private onChangeCallback: (_: any) => {};
  private onTouchedCallback: (_: any) => {};

  constructor(private elementRef: ElementRef,
              private cdr: ChangeDetectorRef,
              private renderer: Renderer2) {
  }

  ngOnInit() {
    this.filteredOptions = this._filter(this.inputValue!);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.filteredOptions = this._filter(this.inputValue!);
    }
  }

  displayFn = (item: any) => {
    return item && item[this.displayField] ? item[this.displayField] : '';
  }

  writeValue(value: any) {
    this.selectedValue = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onInputChange(event: any) {
    const value = event.target.value;
    this.filteredOptions = this._filter(value);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    const value = event.option.value.value;
    this.selectedValue = value;
    this.propagateChanges(this.selectedValue);
    this.autocompleteInput.nativeElement.blur();
  }


  reset() {
    this.formControl.reset('');
    this._filter(this.value!);
  }

  setDisabledState ?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef, 'disabled', isDisabled);
  }

  private _filter(value: string): any[] {
    const filterValue = value ? value.toLowerCase() : '';

    return this.options.filter((option: any) =>
      this.withObjects ?
        option[this.displayField].toLowerCase().indexOf(filterValue) === 0 :
        option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  private propagateChanges(value: any) {
    this.optionSelected.emit(value);
    this.onChangeCallback(value);
    this.onTouchedCallback(value);
  }

  get showErrors() {
    if (!this.formControl) {
      return false;
    }
    return this.formControl.invalid && this.formControl.touched;
  }

  onBlur() {
    this.autocompleteInput.nativeElement.value = this.selectedValue;
  }

  onFocus() {
    if (this.selectedValue) {
      this.autocompleteInput.nativeElement.value = '';
      this.selectedValue = '';
      this.filteredOptions = this._filter('');
      this.propagateChanges('');
    }
  }
}
