import {Component, HostBinding, Input, OnChanges, SimpleChanges} from '@angular/core';

// @ts-ignore
import {ChartDataSets} from "chart.js";

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent {
  @Input() type: any;
  @Input() data: any;
  @Input() options: any;
  @Input() @HostBinding('class.responsive') responsive = false;
  @Input() labels: any;
  @Input() legend: any;
  @Input() plugins: any;
  @Input() width: any;
  @Input() height: any;

  get hasData(): boolean {
    return this.data && this.data?.length > 0;
  }
}
