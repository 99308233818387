import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ButtonsComponent, ContentComponent, Error404Component, InputsComponent } from '@ngnizer/ux';
import { ApplicationDetailsCardComponent, CustomAppCardComponent, EstimateComponent } from '@ngnizer/estimate';
import { LanguageGuard } from '../../libs/ngnizer/ux/src/lib/guards/language.guard';
import { DevModeGuard } from '../../libs/ngnizer/ux/src/lib/guards/dev.guard';

const appRoutes: Routes = [
  {
    path: 'demo',
    canActivate: [DevModeGuard],
    loadChildren: () => import('../../libs/ngnizer/ux/src/lib/ux.module').then(m => m.UxModule)
  },
  {
    path: ':lang',
    component: EstimateComponent,
    canActivate: [LanguageGuard],  // Ensure language is valid before navigating
    children: [
      { path: 'app/custom', component: CustomAppCardComponent },
      { path: 'app/:id', component: ApplicationDetailsCardComponent },
      { path: '**', redirectTo: 'app/custom', pathMatch: 'full' }
    ]
  },
  { path: '', redirectTo: 'en', pathMatch: 'full' },  // Redirect to default language if no path is specified
  { path: '**', redirectTo: 'en', pathMatch: 'full' },
  { path: 'error404', component: Error404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
