import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Theme } from './models/theme.model';
import { UXActions, UXReducer } from './state/';
import { UXState } from './state/ux.reducer';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChipsModel } from './components/inputs/input-chips/chips.model';
import { ArrayValidators } from './utils';
import { ChartColumn, ChartItem } from './models/chart-data.model';

@UntilDestroy()
@Component({
  selector: 'app-ux',
  templateUrl: './ux.component.html',
  styleUrls: ['./ux.component.scss']
})
export class UxComponent implements OnInit {

  /** Buttons **/
  isIconButtonSelected = false;
  isSelectionIndicatorSelected = false;
  isRoundedButtonSelected = false;
  isTabButtonSelected = false;
  isMenuButtonSelected = false;

  /** Inputs **/
  inputsForm: FormGroup;

  inputWidth = '100%';

  personnelChips = [
    new ChipsModel(
      {
        id: 1,
        name: 'Charles Darwin',
        img: 'https://www.nation.com.pk/digital_images/medium/2022-02-06/charles-darwin-stole-his-theory-of-evolution-from-fellow-naturalist-new-book-claims-1644140860-7974.jpg'
      }),
    new ChipsModel(
      {
        id: 2,
        name: 'Adam Smith',
        img: 'https://static01.nyt.com/images/2017/09/18/business/18db-bookentry1/18db-bookentry1-superJumbo.jpg'
      }),
    new ChipsModel({
      id: 3,
      name: 'Daniel Khaneman',
      img: 'https://www.nobelprize.org/images/kahneman-13673-landscape-medium.jpg'
    }),
    new ChipsModel({
      id: 4,
      name: 'George P. Boole',
      img: 'https://cdn.britannica.com/68/6768-050-A7F2D15C/engraving-George-Boole.jpg'
    })
  ];

  inputOptions = [
    {
      name: 'Boole',
      value: 'Boole',
      selected: false,
      id: 1
    },
    {
      name: 'Darwin',
      value: 'Darwin',
      selected: false,
      id: 2
    }, {
      name: 'Smith',
      value: 'Smith',
      false: false,
      id: 3
    }, {
      name: 'Khaneman',
      value: 'Khaneman',
      selected: false,
      id: 4,
      color: 'accent'
    }
  ];

  /*** Cards **/
  isCardSelected = false;
  colorCardText = 'This is a card with it\'s avatar set to a specific color';
  imageCardText = 'This is a card with it\'s avatar set to an image';
  truncatedCardContent = 'This card is selectable and can be used inside of a form, as it implements the ControlValueAccessor interface.\n It\s content is truncated with an ellipsis and a tooltip will display a bit more information about the content.\n';

  /*** Stepper **/
  selectedStepIndex = 0;


  /** GANTT **/
  devTeam = { sprintVelocity: 270 };
  modules: any[] = [
    {
      id: 'auth',
      key: 'Authentication',
      complexity: { total: 212 }
    },
    {
      id: 'intro',
      key: 'Introduction',
      complexity: { total: 13 }
    },
    {
      id: 'chat',
      key: 'Chat',
      complexity: { total: 375 }
    },
    {
      id: 'profile',
      key: 'Profile',
      complexity: { total: 304 }
    },
    {
      id: 'conferencing',
      key: 'Conferencing',
      complexity: { total: 850 }
    },
    {
      id: 'presentation',
      key: 'Presentation',
      complexity: { total: 336 }
    },
    {
      id: 'tags',
      key: 'Tags',
      complexity: { total: 25 }
    },
    {
      id: 'ux',
      key: 'UX',
      complexity: { total: 832 }
    },
    {
      id: 'audit',
      key: 'Audit',
      complexity: { total: 374 }
    }
  ];

  moduleChartData: ChartItem[] = [];

  addModuleToChartData(modules: any[]) {
    for (let i = 0; i < modules.length; i++) {
      const startDate = i === 0 ? new Date() : this.moduleChartData[i - 1].endDate;
      const duration = this.convertComplexityToDays(modules[i].complexity, this.devTeam.sprintVelocity);
      const endDate = new Date(startDate.getTime() + this.convertDaysToMiliseconds(duration));

      this.moduleChartData.push(new ChartItem({
        id: modules[i].id,
        name: modules[i].key,
        startDate: startDate,
        endDate: endDate,
        duration: this.convertDaysToMiliseconds(duration),
        percentComplete: Math.floor(Math.random() * 100),
        dependencies: i === 0 ? null : `${modules[0].id},${modules[i - 1].id}`,
        customData: []
      }));
    }
    ;

  }

  title = 'My Gantt';
  columnNames = [
    new ChartColumn({ type: 'string', name: 'Task ID' }),
    new ChartColumn({ type: 'string', name: 'Task Name' }),
    new ChartColumn({ type: 'date', name: 'Start Date' }),
    new ChartColumn({ type: 'date', name: 'End Date' }),
    new ChartColumn({ type: 'number', name: 'Duration' }),
    new ChartColumn({ type: 'number', name: 'Percent Complete' }),
    new ChartColumn({ type: 'string', name: 'Dependencies' })
  ];

  options = {
    height: 400,
    gantt: {
      criticalPathEnabled: false, // highlight critical path
      trackHeight: 30
    }
  };

  /** END OF GANTT **/


  onStepChange(stepIndex: number) {
    this.selectedStepIndex = stepIndex;
  }


  tabs = [
    {
      label: this.translate.instant('ux.buttons.title'),
      path: 'buttons',
      active: false,
      icon: 'gamepad',
      visible: true,
      svgIcon: ''
    },
    {
      label: this.translate.instant('ux.inputs.title'),
      path: 'inputs',
      active: false,
      icon: 'padding',
      visible: true,
      svgIcon: ''
    },
    {
      label: this.translate.instant('ux.content.title'),
      path: 'content',
      active: false,
      icon: 'image',
      visible: true,
      svgIcon: ''
    }
  ];
  themes: any[] = [
    {
      icon: 'wb_sunny',
      label: 'Light',
      value: Theme.Light
    },
    {
      icon: 'dark_mode',
      label: 'Dark',
      value: Theme.Dark
    }
  ];

  home = 'how';

  theme$: Observable<Theme> | null;

  constructor(private store: Store<UXState>,
              private translate: TranslateService,
              private fb: FormBuilder) {
    this.theme$ = this.store.pipe(select(UXReducer.getTheme));

    this.inputsForm = this.fb.group({
      text: ['', [Validators.required, Validators.max(50)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      number: ['', [Validators.required, Validators.min(6), Validators.max(8)]],
      multi: [[], [ArrayValidators.maxLength(2)]],
      chips: [[], [Validators.required]],
      dropdown: ['', [Validators.required]],
      radio: [this.inputOptions, [ArrayValidators.minSelected(1)]],
      switch: [{ value: false, disabled: false }, []],
      date: [null, [Validators.required]],
      textarea: ['', [Validators.required, Validators.minLength(20)]]
    });
  }

  ngOnInit(): void {
    // this.addModuleToChartData(this.modules);
  }

  onOptionClick(option: any) {
    switch (option) {
      case Theme.Light:
        this.store.dispatch(UXActions.selectTheme({
          theme: Theme.Light
        }));
        break;
      case Theme.Dark:
        this.store.dispatch(UXActions.selectTheme({
          theme: Theme.Dark
        }));
        break;
    }
  }

  control(controlName: string) {
    return this.inputsForm.get(controlName) as FormControl;
  }

  convertDaysToMiliseconds(days: number) {
    return days * 24 * 60 * 60 * 1000;
  }

  convertComplexityToDays(complexity: any, sprintVelocity: number) {
    const daysPerSprint = 14;
    return Math.ceil(complexity.total / sprintVelocity * daysPerSprint);
  }
}
