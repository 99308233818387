import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-switch',
  templateUrl: './input-switch.component.html',
  styleUrls: ['./input-switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputSwitchComponent,
      multi: true
    }
  ]
})
export class InputSwitchComponent implements ControlValueAccessor {

  @Input() formControl: FormControl;
  @Input() label = '';

  @Input() labelPosition: 'before' | 'after' = 'after';
  @Output() switchChange = new EventEmitter<any>();

  private privateValue = false;


  @HostBinding('class.after') get isLabelAfter() {
    return this.labelPosition === 'after';
  }

  @Input()
  set value(value) {
    this.privateValue = value;
  }

  get value() {
    return this.privateValue;
  }

  private onChangeCallback: (_: any) => {};
  private onTouchedCallback: (_: any) => {};

  writeValue(value: any) {
    this.privateValue = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onSwitchChange(event: any) {
    this.switchChange.emit(event.checked);
    if (this.onChangeCallback) {
      this.onChangeCallback(event.checked);
    }
    if (this.onTouchedCallback) {
      this.onTouchedCallback(event.checked);
    }
  }

}
