import {Complexity} from "./complexity.model";
import {Module} from "./module.model";
import {ApplicationCost} from "./applicationCost.model";

export class Application {
  public id: string;
  public key: string;
  public modules: Module[];
  public complexity: Complexity;
  public cost: ApplicationCost;

  constructor(app: any = {}) {
    this.id = app?.id || '';
    this.key = app?.key || '';
    this.modules = app?.modules?.map((module: any) => new Module(module)) || [];
    this.complexity = app?.complexity || null;
    this.cost = app.cost ? new ApplicationCost(app.cost) : null;
  }
}
