export class ChartItem {
    public id: string;
    public name: string;
    public startDate: Date;
    public endDate: Date;
    public duration: number; // in miliseconds
    public percentComplete: number;
    public dependencies: string; // list of ids separated by commas
    public customData: any[];

    constructor(chartItem: any) {
        this.id = chartItem.id;
        this.name = chartItem.name;
        this.startDate = chartItem.startDate;
        this.endDate = chartItem.endDate;
        this.duration = chartItem.duration;
        this.percentComplete = chartItem.percentComplete;
        this.dependencies = chartItem.dependencies;
        this.customData = chartItem.customData;
    }

    get data(): any[] {
        return [this.id, this.name, this.startDate, this.endDate, this.duration, this.percentComplete, this.dependencies, ...this.customData];
    }
}

export class ChartColumn {
    public type: 'string' | 'date' | 'number';
    public name: string;

    constructor(chartColumn: any) {
        this.type = chartColumn.type;
        this.name = chartColumn.name;
    }
}
