import {Component, Inject} from '@angular/core';

import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {select, Store} from '@ngrx/store';
import {ActivatedRoute} from "@angular/router";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {BottomSheetComponent} from "../bottom-sheet/bottom-sheet.component";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";

import * as estimateReducers from '../../state/estimate.reducer';
import * as estimateActions from '../../state/estimate.actions';

import {Application} from "../../models/application.model";
import {Observable} from "rxjs";
import {filter} from "rxjs/operators";
import {Module} from "../../models";
import {TranslateService} from "@ngx-translate/core";
import {ApplicationCost} from "../../models/applicationCost.model";


@UntilDestroy()
@Component({
  selector: 'app-application-details-card',
  templateUrl: './application-details-card.component.html',
  styleUrls: ['./application-details-card.component.scss']
})
export class ApplicationDetailsCardComponent {

  appName: string;

  selectedApp$: Observable<Application>;
  selectedModule$: Observable<Module>;
  isLoading$: Observable<boolean>;

  selectedApp: Application;
  selectedModule: Module;

  isWeb = false;


  constructor(
    @Inject('environment') private environment: any,
    private route: ActivatedRoute,
    private store: Store<any>,
    private translate: TranslateService,
    private _bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver
  ) {

    this.route.params.subscribe(params => {
      this.appName = params['id'];
      this.store.dispatch(estimateActions.loadApplication({key: this.appName}));
    });

    this.breakpointObserver.observe([
      "(max-width: 959px)"
    ]).subscribe((result: BreakpointState) => {
      this.isWeb = !result.matches;
    });


    this.isLoading$ = this.store.pipe(select(estimateReducers.isLoading));
    this.selectedApp$ = this.store.pipe(select(estimateReducers.getSelectedApp));

    this.selectedApp$.pipe(filter((app) => !!app), untilDestroyed(this))
      .subscribe((app: Application) => {
        this.selectedApp = app;
      });
  }

  onModuleClick(module: Module) {
    this._bottomSheet.open(BottomSheetComponent, {
      panelClass: 'max-width',
      data: {
        module: module,
        app: this.appName,
        custom: false
      }
    });
  }

  computeCost(total: any) {

    const asKRepresentation = total.toLocaleString('en-US',
      {
        style: 'decimal',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
        useGrouping: true
      });

    return asKRepresentation.split(',')[0] + 'K'
  }

  get team() {
    return this.selectedApp.cost.phase3.find(item => item.isRecommended == true).team;
  }

  computeFixedDuration(cost: ApplicationCost) {
    return cost.phase1.duration + cost.phase2.duration;
  }

  computeVariableDuration(cost: ApplicationCost) {
    let recommendedTeamCost = cost.phase3.find(cost => cost.isRecommended);
    return Math.round(recommendedTeamCost.duration);
  }

  computeTotalDuration(cost: ApplicationCost): any {
    return `assets/icons/duration/duration_${Math.ceil(cost.total.duration)}.svg`;
  }

  computeFixedCost(cost: ApplicationCost) {
    let fixedCost = cost.phase1.total + cost.phase2.total;
    return this.computeCost(fixedCost);
  }

  computeMonthlyCost(cost: ApplicationCost) {
    let recommendedTeamCost = cost.phase3.find(cost => cost.isRecommended);
    return this.computeCost(Math.ceil(recommendedTeamCost.team.monthlyCost));
  }

}
