import {Task} from "./task.model";
import {Complexity} from "./complexity.model";

export class Feature {
  public id: string;
  public key: string;
  public tasks: Task[];
  public complexity: Complexity;

  constructor(feature: any = {}) {
    this.id = feature?.id || '';
    this.key = feature?.key || '';
    this.tasks = feature?.tasks || [];
    this.complexity = new Complexity(feature?.complexity) || null;
  }
}
