<!-- TODO account for loading so that the elements wait before comming into view -->

<ng-container *ngIf="isWeb; then web else mobile"></ng-container>

<ng-template #web>
  <aside class="cards">
    <!-- First tab is reserved for custom application estimation -->
    <app-application-card
      title="{{ ('pages.estimate.applications.'+ CUSTOM_APP_ESTIMATE_KEY +'.title') | translate}}"
      description="{{('pages.estimate.applications.'+ CUSTOM_APP_ESTIMATE_KEY +'.description') | translate}}"
      background="assets/images/backgrounds/m3-dark.jpg"
      avatar="assets/icons/apps/app_{{CUSTOM_APP_ESTIMATE_KEY}}.svg"
      routerLinkActive="selected"
      #rla="routerLinkActive"
      [selected]="rla.isActive"
      [routerLink]="'./app/'+ CUSTOM_APP_ESTIMATE_KEY">
    </app-application-card>
    <!-- The others should come from the server -->
    <ng-container *ngFor="let app of ( applications$ | async ); let index = index">
      <app-application-card
        title="{{ ('pages.estimate.applications.'+ app.key +'.title') | translate}}"
        description="{{('pages.estimate.applications.'+ app.key +'.description') | translate}}"
        background="assets/images/backgrounds/m3-dark.jpg"
        avatar="assets/icons/apps/app_{{app.key}}.svg"
        routerLinkActive="selected"
        #rla="routerLinkActive"
        [selected]="rla.isActive"
        [routerLink]="'./app/'+ app.key">
      </app-application-card>
    </ng-container>
  </aside>
  <app-surface-card class="modules">
    <router-outlet></router-outlet>
  </app-surface-card>
</ng-template>

<ng-template #mobile>

  <p-accordion>
    <!-- First tab is reserved for custom application estimation -->
    <p-accordionTab
      id="{{CUSTOM_APP_ESTIMATE_KEY}}"
      routerLinkActive="selected"
      #rla="routerLinkActive"
      [selected]="isExpanded(CUSTOM_APP_ESTIMATE_KEY)"
      [routerLink]="'./app/'+ CUSTOM_APP_ESTIMATE_KEY">
      <ng-template pTemplate="header">
        <app-application-card
          title="{{ ('pages.estimate.applications.'+ CUSTOM_APP_ESTIMATE_KEY +'.title') | translate}}"
          description="{{('pages.estimate.applications.'+ CUSTOM_APP_ESTIMATE_KEY +'.description') | translate}}"
          background="assets/images/backgrounds/m3-dark.jpg"
          avatar="assets/icons/apps/app_{{CUSTOM_APP_ESTIMATE_KEY}}.svg"
          routerLinkActive="selected"
          (appClick)="scrollToElement(CUSTOM_APP_ESTIMATE_KEY)"
          [selected]="rla.isActive">
        </app-application-card>
      </ng-template>
      <ng-template pTemplate="content">
        <router-outlet></router-outlet>
      </ng-template>
    </p-accordionTab>
    <!-- The others should come from the server -->
    <ng-container *ngFor="let app of ( applications$ | async ); let index = index">
      <p-accordionTab
        id="{{app.key}}"
        routerLinkActive="selected"
        #rla="routerLinkActive"
        [selected]="isExpanded(app.key)"
        [routerLink]="'./app/'+ app.key">
        <ng-template pTemplate="header">
          <app-application-card
            title="{{ ('pages.estimate.applications.'+ app.key +'.title') | translate}}"
            description="{{('pages.estimate.applications.'+ app.key +'.description') | translate}}"
            background="assets/images/backgrounds/m3-dark.jpg"
            avatar="assets/icons/apps/app_{{app.key}}.svg"
            routerLinkActive="selected"
            (appClick)="scrollToElement(app.key)"
            [selected]="rla.isActive">
          </app-application-card>
        </ng-template>
        <ng-template pTemplate="content">
          <router-outlet></router-outlet>
        </ng-template>
      </p-accordionTab>
    </ng-container>
  </p-accordion>
</ng-template>


<ng-template #loadingSpinner>
  <app-loading-spinner></app-loading-spinner>
</ng-template>



