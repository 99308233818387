import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss']
})
export class FabComponent implements OnInit, AfterViewInit {
  @ViewChild('fabControl', {static: false}) fabControl!: ElementRef;

  @Input() type = 'add';
  @Input() icon = 'add';
  @Input() disabled = false;

  @Output() clickOnFab = new EventEmitter<any>();


  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (this.type === 'toolbar') {
      $(this.fabControl.nativeElement).floatingActionButton({
        toolbarEnabled: true
      });
    }
  }

  fabClick() {
    this.clickOnFab.emit();
  }

}
