import * as uxActions from './ux.actions';

import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { Theme } from '../models/theme.model';

export const uxFeatureKey = 'ux';

export interface UXState {
  theme: Theme;
}

export const initialUXState: UXState = {
  theme: Theme.Dark,
};

const uxReducer = createReducer(
    initialUXState,

    on(uxActions.selectTheme, (state, {theme}) => ({
      ...state,
      theme,
    }))
);

export function reducer(state: UXState | undefined, action: Action) {
  return uxReducer(state, action);
}

export const getUXState = createFeatureSelector<UXState>(uxFeatureKey);
export const getTheme = createSelector(getUXState, (state: UXState) => state.theme);
