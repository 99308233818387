<!-- Conditionally display header if content is provided -->
<div class="header">
  <ng-content select="app-material-header"></ng-content>
</div>

<!-- Modal body content -->
<div class="body">
  <ng-content select="app-material-body"></ng-content>
</div>

<!-- Conditionally display footer if content is provided -->
<div class="footer">
  <ng-content select="app-material-footer"></ng-content>
</div>
