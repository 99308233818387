import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {map} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import * as estimateActions from "./state/estimate.actions";

@Injectable()
export class EstimateNotificationEffects {

  constructor(private toastr: ToastrService,
              private translateService: TranslateService,
              private actions$: Actions) {
  }

  // tslint:disable-next-line
  submitOfferSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(estimateActions.submitOfferSuccess),
        map(() => {
            this.toastr.success(
              this.translateService.instant('general.actions.success'),
              this.translateService.instant('pages.notifications.estimationSubmitSuccess'), {timeOut: 300000}
            )
          }
        )
      ),
    {dispatch: false} // Indicates that this effect does not dispatch any action
  );

  // tslint:disable-next-line
  submitOfferFailed$ = createEffect(() =>
      this.actions$.pipe(
        ofType(estimateActions.submitOfferFailed),
        map(() => {
            this.toastr.error(
              this.translateService.instant('general.actions.failed'),
              this.translateService.instant('pages.notifications.estimationSubmitFailed')
            )
          }
        )
      ),
    {dispatch: false} // Indicates that this effect does not dispatch any action
  );
}
