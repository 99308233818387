import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';

@Component({
  selector: 'app-circle-card',
  templateUrl: './circle-card.component.html',
  styleUrls: ['./circle-card.component.scss']
})
export class CircleCardComponent {
  static LONG_PRESS_DURATION = 500;

  @Input() content = '';
  @Input() name = '';
  @Input() @HostBinding('class.type') type: 'image' | 'text' = 'image';
  @Input() @HostBinding('class') flavor: 'module' | 'feature' | 'team' | 'duration' | 'cost' = 'module';
  @Input() @HostBinding('class.fixed') fixed = false
  @Input() @HostBinding('class.selected') selected = false

  @Output() clicked = new EventEmitter<void>();
  @Output() pressed = new EventEmitter<void>();

  private pressTimer: any;
  private isLongPress = false;
  private touchEventTriggered = false;

  @HostListener('touchstart', ['$event'])
  onTouchStart(event: TouchEvent) {
    this.touchEventTriggered = true; // Mark that a touch event was triggered
    this.onPressStart(event);
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent) {
    if (!this.touchEventTriggered) {
      this.onPressStart(event);
    }
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    this.onPressEnd(event);
    setTimeout(() => {
      this.touchEventTriggered = false; // Reset after a short delay to handle the next interaction
    }, 0);
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event: MouseEvent) {
    if (!this.touchEventTriggered) {
      this.onPressEnd(event);
    }
  }

  private onPressStart(event: MouseEvent | TouchEvent) {
    this.isLongPress = false;

    // Start the timer to detect long press
    this.pressTimer = setTimeout(() => {
      this.isLongPress = true;
      this.pressed.emit();
    }, CircleCardComponent.LONG_PRESS_DURATION);
  }

  private onPressEnd(event: MouseEvent | TouchEvent) {
    // Clear the long press timer
    clearTimeout(this.pressTimer);

    // If it wasn't a long press, trigger a regular click event
    if (!this.isLongPress) {
      this.clicked.emit();
    }
  }


}
