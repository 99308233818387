import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements AfterViewInit {

  @Input() index = 0;

  @HostBinding('class.inverted') @Input() inverted = false;
  @HostBinding('class') @Input() type: 'rounded' | 'tab' | 'menu' = 'rounded';
  @HostBinding('class.with-icon') @Input() icon = '';
  @HostBinding('class.selected') @Input() selected = false;

  @Output() appClick = new EventEmitter<any>();

  isHover = false;


  @HostListener('mouseover') mouseover() {
    this.isHover = true;
  }

  @HostListener('mouseleave') mouseleave() {
    this.isHover = false;
  }

  @HostListener('click', ['$event'])
  public onClick(event: PointerEvent): void {
    this.appClick.emit(this.index);
  }

  ngAfterViewInit(): void {
  }


}
