export class Complexity {
  public api: number;
  public arch: number;
  public infra: number;
  public req: number;
  public ui: number;
  public total: number;


  constructor(complexity: any = {}) {
    this.api = complexity?.api || 0;
    this.arch = complexity?.arch || 0;
    this.infra = complexity?.infra || 0;
    this.infra = complexity?.infra || 0;
    this.req = complexity?.req || 0;
    this.ui = complexity?.ui || 0;
    this.total = complexity?.total || 0;
  }
}
