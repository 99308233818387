import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialLibraryModule } from './_libraries/_material-library.module';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIcon } from '@angular/material/icon';
import { ButtonsComponent } from './buttons/buttons.component';
import { InputsComponent } from './inputs/inputs.component';
import { CardComponent } from './content/card/card.component';
import { CardAvatarComponent } from './content/card/card-avatar/card-avatar.component';
import { CardContentComponent } from './content/card/card-content/card-content.component';
import { CardActionsComponent } from './content/card/card-actions/card-actions.component';
import { SelectionIndicatorComponent } from './buttons/selection-indicator/selection-indicator.component';
import { StepperComponent } from './content/stepper/stepper.component';
import { StepComponent } from './content/stepper/components/step/step.component';
import { StepActionsComponent } from './content/stepper/components/step-actions/step-actions.component';
import { ButtonComponent, FabComponent, IconButtonComponent } from './buttons';
import {
  CheckboxComponent,
  ImgUploaderComponent,
  InputChipsComponent,
  InputComponent,
  InputDropdownAutocompleteComponent,
  InputMultitextComponent,
  InputRadioComponent,
  InputSwitchComponent,
  InputTextareaComponent,
  InputValidationErrorsComponent
} from './inputs';
import { AppbarComponent, SidenavComponent } from './navigation';
import {
  AcronymComponent,
  AvatarComponent, ChartComponent,
  ContentComponent,
  DataTableComponent,
  FooterComponent,
  FullRowSectionComponent,
  LoadingSpinnerComponent,
  MaterialComponent,
  MessageDialogComponent, SurfaceComponent,
  TextboxComponent,
  TourStepTemplateComponent, TreeComponent
} from './content';
import {
  MaterialBodyComponent,
  MaterialFooterComponent,
  MaterialHeaderComponent,
  MaterialTabComponent,
  MaterialTabsComponent
} from './content/material/components';
import {
  Error404Component,
  HomeComponent,
  HowItWorksComponent,
  TermsAndConditionsComponent,
  ThankYouComponent
} from './pages';
import { HoverClassDirective } from '../directives/hover-class.directive';
import { NGXLibraryModule } from './_libraries/_ngx-library.module';
import { PrimeNGLibraryModule } from "./_libraries/_primeng-library.module";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCard } from "@angular/material/card";
import { AccordionModule } from "primeng/accordion";
import { GanttChartComponent } from './content/gantt/gantt-chart.component';


const buttons = [
  IconButtonComponent,
  FabComponent,
  ButtonComponent,
  SelectionIndicatorComponent
];

const inputs = [
  InputDropdownAutocompleteComponent,
  CheckboxComponent,
  InputValidationErrorsComponent,
  ImgUploaderComponent,
  InputComponent,
  InputSwitchComponent,
  InputMultitextComponent,
  InputRadioComponent,
  InputTextareaComponent,
  InputChipsComponent,
];

const navigation = [
  AppbarComponent,
  SidenavComponent
];

const render = [
  AcronymComponent,
  AvatarComponent,
  DataTableComponent,
  FullRowSectionComponent,
  LoadingSpinnerComponent,
  MaterialComponent,
  MaterialBodyComponent,
  MaterialHeaderComponent,
  MaterialFooterComponent,
  MaterialTabsComponent,
  MaterialTabComponent,
  MessageDialogComponent,
  TextboxComponent,
  TourStepTemplateComponent,
  FooterComponent,
  StepperComponent,
  StepComponent,
  StepActionsComponent,
  CardComponent,
  CardActionsComponent,
  CardAvatarComponent,
  CardContentComponent,
  SurfaceComponent,
  ChartComponent,
  TreeComponent,
  GanttChartComponent
];

const pages = [
  HomeComponent,
  HowItWorksComponent,
  TermsAndConditionsComponent,
  Error404Component,
  ThankYouComponent,
  ContentComponent
];

const directives = [
  HoverClassDirective
];

@NgModule({
  declarations: [
    buttons,
    inputs,
    navigation,
    render,
    pages,
    directives,
    ButtonsComponent,
    InputsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NGXLibraryModule,
    MaterialLibraryModule,
    PrimeNGLibraryModule
  ],
  exports: [
    buttons,
    inputs,
    navigation,
    render,
    pages,
    directives,
    // TODO Should be replaced with our own components
    MatTooltipModule,
    MatStepperModule,
    MatIcon,
    MatCard,
    MatExpansionModule,
    AccordionModule,
    GanttChartComponent
  ]
})
export class ComponentsModule {
}
