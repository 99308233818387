import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import * as UXReducer from './state/ux.reducer';
import { AuthorizationHeaderInterceptor, ErrorHandlingInterceptor } from './interceptors';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from './components';
import { UxComponent } from './ux.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UXRoutingModule } from './ux.routing';


export const environment_token = new InjectionToken<any>('environment');

@NgModule({
  declarations: [
    // Containers
    UxComponent
  ],
  imports: [
    // Modules
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forFeature(UXReducer.uxFeatureKey, UXReducer.reducer),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // Component modules
    ComponentsModule,
    UXRoutingModule
  ],
  exports: [
    // Modules
    CommonModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    // Component modules
    UxComponent,
    ComponentsModule
  ]
})
export class UxModule {
  static forRoot(environment?: any): ModuleWithProviders<UxModule> {
    return {
      ngModule: UxModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthorizationHeaderInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorHandlingInterceptor,
          multi: true
        },
        {
          provide: environment_token,
          useValue: environment
        }
      ]
    };
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

