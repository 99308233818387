<mat-form-field appearance="fill">
  <mat-chip-grid #chipGrid aria-label="Enter fruits">
    <mat-chip-row *ngFor="let record of records"
                  [removable]="removable"
                  [disabled]="readonly"
                  (removed)="remove(record)">
      <img *ngIf="record?.img" matChipAvatar src="{{record?.img}}">
      {{ display(record) }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>
  </mat-chip-grid>
  <input
      #chipsInput
      matInput
      #trigger="matAutocompleteTrigger"
      [placeholder]="label"
      [formControl]="recordControl"
      [matAutocomplete]="auto"
      [readOnly]="readonly"
      [required]="required"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="addOnBlur"
      (matChipInputTokenEnd)="add()"
      (mousedown)="$event.stopPropagation();" />
  <mat-icon class="field-icon"
            [class.material-icons]="hasRecords"
            [class.material-icons-outlined]="!hasRecords"
            matSuffix>style
  </mat-icon>
  <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)">
    <mat-option *ngFor="let record of filteredRecords | async" [value]="record">
      {{ display(record) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<app-form-error-list
    *ngIf="showErrors"
    [errorMap]="formControl.errors">
</app-form-error-list>
