<mat-stepper orientation="horizontal"
             [linear]="true"
             [selectedIndex]="selectedIndex"
             (selectionChange)="selectionChangeStepper($event)"
             #stepper >
    <ng-template matStepperIcon="edit" label>
        <mat-icon>done</mat-icon>
    </ng-template>
    <mat-step>
        <ng-template matStepLabel>
            <ng-content select="[step1-title]"></ng-content>
        </ng-template>
        <div class="stepper-content stepper-content-intro">
        <ng-content select="[step1-sections]"></ng-content>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            <ng-content select="[step2-title]"></ng-content>
        </ng-template>
        <div class="stepper-content stepper-content-form">
            <ng-content select="[step2-sections]"></ng-content>
        </div>
        <app-total-cost-estimate [total]="total"></app-total-cost-estimate>
        <div class="step-buttons">
                <button class="btn-prev" mat-button matStepperPrevious>
                    <mat-icon>keyboard_arrow_left</mat-icon>
                    {{'general.texts.back' | translate}}
                </button>
                <button class="btn-next" mat-button matStepperNext>
                    {{'general.texts.next' | translate}}
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            <ng-content select="[step3-title]"></ng-content>
        </ng-template>
        <div class="stepper-content stepper-content-contact">
            <ng-content select="[step3-sections]"></ng-content>
        </div>
        <app-total-cost-estimate [total]="total"></app-total-cost-estimate>
        <div class="step-buttons">
            <button class="btn-prev" mat-button matStepperPrevious>
                <mat-icon>keyboard_arrow_left</mat-icon>
                {{'general.texts.back' | translate}}
            </button>
            <button class="btn-next" mat-button matStepperNext></button>
        </div>
    </mat-step>
</mat-stepper>
