import {Module} from "./module.model";

export class OfferRequest {
  public email: string;
  public description: string;
  public modules: any[];

  constructor(offer: any = {}) {
    this.email = offer?.email || '';
    this.description = offer?.description || '';
    this.modules = offer?.modules || [];
  }
}
