import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { NgxImgComponent } from 'ngx-img';

import { base64StringToBlob } from 'blob-util';

@Component({
  selector: 'app-img-uploader',
  templateUrl: './img-uploader.component.html',
  styleUrls: ['./img-uploader.component.scss']
})
export class ImgUploaderComponent implements OnInit {
  private static readonly BASE64_KEY = 'base64,';

  @ViewChild('img', {static: false}) img: any;
  @ViewChild(NgxImgComponent, {static: false}) cropper: NgxImgComponent;

  @Input() maxImageSize = 5120;
  @Input() allowedImageTypes = ['image/png', 'image/jpeg'];
  @Input() selectOnly = false;

  @Output() imageSelected = new EventEmitter<any>();
  @Output() upload = new EventEmitter<any>();
  @Output() closeUpload = new EventEmitter<any>();

  config = {
    fileSize: this.maxImageSize,
    fileType: this.allowedImageTypes,
    crop: [
      {
        autoCropArea: 1,
        ratio: 1
      }
    ]
  };

  private imgSrc = '';

  constructor() {
  }

  ngOnInit() {
    this.config.fileSize = this.maxImageSize;
    this.config.fileType = this.allowedImageTypes;
  }

  onSelect(base64Pic: string) {
    this.imgSrc = base64Pic;
    this.imageSelected.emit(this.file);
  }

  onReset() {
    this.imgSrc = '';
  }

  onUpload() {
    this.upload.emit(this.file);
    this.cropper.reset();
  }

  onClose() {
    this.closeUpload.emit();
  }

  private get file() {
    const base64Pic = this.imgSrc.substring(this.imgSrc.indexOf(ImgUploaderComponent.BASE64_KEY) + ImgUploaderComponent.BASE64_KEY.length);
    return new File([base64StringToBlob(base64Pic)], this.cropper.fileName, {type: this.cropper.file.type});
  }

  get hasSrc(): boolean {
    return !!this.imgSrc;
  }
}
