import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-form-section-feature-card',
  templateUrl: './form-section-feature-card.component.html',
  styleUrls: ['./form-section-feature-card.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FormSectionFeatureCardComponent,
      multi: true
    }
  ]
})
export class FormSectionFeatureCardComponent implements ControlValueAccessor {

  @ViewChild(MatTooltip) tooltip: MatTooltip;

  @HostBinding('class') get class() {
    return this.mode;
  }

  @HostBinding('class.selected') selected = false;


  @Input() mode: 'rectangular' | 'circular' = 'rectangular';

  @Input() name = '';
  @Input() icon = '';
  @Input() description = '';

  @Output() selectFeature = new EventEmitter<any>();


  private onChangeCallback: (_: any) => {};
  private onTouchedCallback: (_: any) => {};

  constructor() {
  }

  @HostListener('click') onClick() {
    this.onSelect();
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: boolean): void {
    this.selected = obj;
  }

  onSelect() {
    this.selected = !this.selected;
    this.propagateChanges(this.selected);
  }

  private propagateChanges(newSelect: boolean) {

    if (this.onTouchedCallback) {
      this.onTouchedCallback(newSelect);
    }

    if (this.onChangeCallback) {
      this.onChangeCallback(newSelect);
    }

  }
}
