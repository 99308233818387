import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationErrors } from '@angular/forms';

@Component({
  selector: 'app-form-error-list',
  templateUrl: './input-validation-errors.component.html',
  styleUrls: ['./input-validation-errors.component.scss']
})
export class InputValidationErrorsComponent {
  @Input('errorMap')
  get errorMap() {
    return this.errorList;
  }

  set errorMap(errorMap) {
    this.currentErrorMap = errorMap;
    this.generateErrorMessages(this.currentErrorMap);
  }

  errorList: ValidationErrors = [];
  private currentErrorMap = {};

  constructor(private translateService: TranslateService) {
  }

  private generateErrorMessages(errorMap: any) {
    this.errorList = [];
    for (const errorKey in errorMap) {
      if (errorMap.hasOwnProperty(errorKey)) {
        this.errorList.push(this.translateService.instant(`ux.errors.form.${errorKey}`, errorMap[errorKey]));
      }
    }
  }
}
