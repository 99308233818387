import { Component } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent {

  /*** Cards **/
  isCardSelected = false;
  colorCardText = 'This is a card with it\'s avatar set to a specific color';
  imageCardText = 'This is a card with it\'s avatar set to an image';
  truncatedCardContent = 'This card is selectable and can be used inside of a form, as it implements the ControlValueAccessor interface.\n It\s content is truncated with an ellipsis and a tooltip will display a bit more information about the content.\n'

  /*** Stepper **/
  selectedStepIndex = 0;

  onStepChange(stepIndex: number) {
    this.selectedStepIndex = stepIndex;
  }
}
