<div class="howitworks">
    <app-full-row-section
            index="0"
            orientation="s-reverse"
            [parentScroll]="scroll"
            image="assets/howitworks/problem.svg"
            heading="{{'pages.howitworks.sections.zero.header' | translate}}"
            text="{{'pages.howitworks.sections.zero.text' | translate}}">
    </app-full-row-section>
    <app-full-row-section
            index="1"
            orientation="s-reverse"
            [parentScroll]="scroll"
            image="assets/howitworks/rocket.svg"
            heading="{{'pages.howitworks.sections.one.header' | translate}}"
            text="{{'pages.howitworks.sections.one.text' | translate}}">
    </app-full-row-section>
    <app-full-row-section
            class="make-section"
            [hasCustomText]="true"
            index="2"
            orientation="s-reverse"
            [parentScroll]="scroll"
            image="assets/howitworks/make.svg">
        <ng-container custom>
            <div class="line"></div>
            <h1 class="title">{{'pages.howitworks.sections.two.header' | translate}}</h1>
            <p class="description" [innerHTML]="'pages.howitworks.sections.two.text' | translate"></p>
            <app-acronym letter="M"
                         text="otivation"
                         description="{{'pages.howitworks.sections.two.make.mdescription' | translate}}">
            </app-acronym>
            <app-acronym letter="A"
                         text="ctors"
                         description="{{'pages.howitworks.sections.two.make.adescription' | translate}}">
            </app-acronym>
            <app-acronym letter="K"
                         text="ey Usages"
                         description="{{'pages.howitworks.sections.two.make.kdescription' | translate}}">
            </app-acronym>
            <app-acronym letter="E"
                         text="nterprise Integrations"
                         description="{{'pages.howitworks.sections.two.make.edescription' | translate}}">
            </app-acronym>
        </ng-container>
    </app-full-row-section>
    <app-full-row-section
            index="3"
            orientation="s-reverse"
            [parentScroll]="scroll"
            (cta)="onCTA()"
            image="assets/howitworks/storypoints.svg"
            heading="{{'pages.howitworks.sections.three.header' | translate}}"
            text="{{'pages.howitworks.sections.three.text' | translate}}"
            ctaText="{{'pages.howitworks.sections.three.cta' | translate}}"
            ctaId="how-start-estimate-btn">
    </app-full-row-section>
</div>
