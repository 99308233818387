<div class="fab-container">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'add'">
            <ng-container *ngTemplateOutlet="add"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'toolbar'">
            <ng-container *ngTemplateOutlet="toolbar"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'footer'">
            <ng-container *ngTemplateOutlet="footer"></ng-container>
        </ng-container>
    </ng-container>
</div>

<ng-template #add>
    <button mat-fab [disabled]="disabled" (click)="fabClick()">
        <mat-icon>{{ icon }}</mat-icon>
    </button>
</ng-template>

<ng-template #toolbar>
    <div class="fab-container"
         [ngClass]="{ 'fab-disabled': disabled }">
        <div #fabControl class="fixed-action-btn toolbar">
            <a class="btn-floating btn-large">
                <i class="large material-icons">keyboard_backspace</i>
            </a>
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>

<ng-template #footer>
    <div class="fab-relative-container">
        <button mat-fab [disabled]="disabled" (click)="fabClick()">
            <i class="large material-icons">{{ icon }} </i>
        </button>
    </div>
</ng-template>
