import {Cost} from "./cost.model";

export class ApplicationCost {
  public phase1: Cost;
  public phase2: Cost;
  public phase3: Cost[];
  public total: Cost;

  constructor(appCost: any = {}) {
    this.phase1 = appCost.phase1 ? new Cost(appCost.phase1) : null;
    this.phase2 = appCost.phase2 ? new Cost(appCost.phase2) : null;
    this.phase3 = appCost?.phase3?.map((cost: any) => new Cost(cost)) || [];
    this.total = appCost.total ? new Cost(appCost.total) : null;
  }
}
