import { Component, OnInit } from '@angular/core';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-tour-step-template',
  templateUrl: './tour-step-template.component.html',
  styleUrls: ['./tour-step-template.component.scss']
})
export class TourStepTemplateComponent implements OnInit {
  steps: IStepOption[] = [
    {
      anchorId: 'start.tour',
      title: 'pages.tourSteps.start',
      enableBackdrop: true
    },
    {
      anchorId: 'step1',
      title: 'pages.tourSteps.step1',
      enableBackdrop: true
    },
    {
      anchorId: 'step2',
      title: 'pages.tourSteps.step2',
      enableBackdrop: true
    },
    {
      anchorId: 'step3',
      title: 'pages.tourSteps.step3',
      enableBackdrop: true
    },
    {
      anchorId: 'step4',
      title: 'pages.tourSteps.step5',
      enableBackdrop: true
    }];

  constructor(public tourService: TourService) {
  }

  ngOnInit(): void {
    this.tourService.initialize(this.steps, {
      route: 'estimate',
      disablePageScrolling: true
    });
  }

}
