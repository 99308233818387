import {Complexity} from "./complexity.model";

export class Task {
  public id: string;
  public key: string;
  public complexity: Complexity;

  constructor(task: any = {}) {
    this.id = task?.id || '';
    this.key = task?.key || '';
    this.complexity = task?.complexity || null;
  }
}
