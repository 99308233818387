import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash-es';
import { Observable, startWith, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChipsModel } from './chips.model';

@Component({
  selector: 'app-input-chips',
  templateUrl: './input-chips.component.html',
  styleUrls: ['./input-chips.component.scss'],
  providers: [
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA]
      }
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputChipsComponent,
      multi: true
    }
  ]
})

export class InputChipsComponent implements ControlValueAccessor, OnInit, OnChanges {

  /**
   * Computed the logical difference between the input 'allRecords'
   * list which contains all the records, and the records that are
   * selected. This allows for the elimination of duplicate autocomplete options
   */
  get diffList() {
    return _.differenceBy(this.availableRecords, this.records, 'name');
  }

  @Input() formControl: FormControl;
  @Input() availableRecords: ChipsModel<any>[] = [];
  @Input() label: string;
  @Input() showErrors = false;
  @Input() required = false;
  @Input() hasAvatar = true;
  @Input() readonly = false;
  @Input() pathToTooltip: string;
  @Input() infoTooltip = '';

  /** This is used in conjunction with the availableRecords field, in order to allow the user to input values
   * that are not part of the availableRecords field. When availableRecords are not present, this parameter is ignored.
   * **/
  @Input() allowExtraValues = false;

  @Output() newRecord: EventEmitter<any> = new EventEmitter();
  @Output() removeRecord: EventEmitter<any> = new EventEmitter();
  @Output() addRecord: EventEmitter<any> = new EventEmitter();


  records: ChipsModel<any>[] = [];
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredRecords: Observable<ChipsModel<any>[]>;
  recordControl = new FormControl();
  isDisabled = true;

  @ViewChild('chipsInput', {static: false}) recordInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  private onChangeCallback: (_: any) => {};
  private onTouchedCallback: (_: any) => {};

  constructor() {
    // @ts-ignore
    this.filteredRecords = this.diffList;
    this.filteredRecords = this.recordControl.valueChanges.pipe(
      startWith(''),
      map((record: string | null) => {
          return record ? this._filter(record) : this.diffList;
        }
      ));
    this.recordControl.valueChanges.subscribe((data) => {
      this.isDisabled = !data;
    });
  }

  add(): void {
    if (!this.matAutocomplete.isOpen) {
      const value = this.recordInput.nativeElement.value;

      if ((value || '').trim()) {
        if (!this.availableRecords || this.availableRecords.length === 0) {
          this.records.push(new ChipsModel({name: value}));
        } else {
          const chipOption = this.availableRecords.find(record => record.name === value);
          if (chipOption) {
            this.records.push(chipOption);
          } else if (this.allowExtraValues) {
            this.records.push(new ChipsModel({name: value}));
          }
        }

        this.newRecord.emit(value);
        // Check if the added value already exists
      }

      // Reset the input value
      this.recordInput.nativeElement.value = '';

      this.recordControl.setValue(null);
      this.onChangeCallback(this.records);
    }
  }

  remove(record: ChipsModel<any>): void {
    const index = this.records.indexOf(record);

    if (index >= 0) {
      const recordsCopy = [...this.records];
      recordsCopy.splice(index, 1);
      this.records = recordsCopy;
      this.onChangeCallback(this.records);
      this.removeRecord.emit();
    }
    this.recordControl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.records === undefined) {
      this.records = [];
    }
    const recordsCopy = [...this.records];
    recordsCopy.push(this._filter(event.option.viewValue)[0]);
    this.records = recordsCopy;
    this.recordInput.nativeElement.value = '';
    this.recordControl.setValue(null);
    this.onChangeCallback(this.records);
    this.recordInput.nativeElement.blur();
    this.isDisabled = false;
    this.addRecord.emit(this.records);
    setTimeout(() => {
      this.recordInput.nativeElement.focus();
    });
  }

  writeValue(value: any): void {
    if (value !== null) {
      this.records = value;
      this.recordControl.updateValueAndValidity({onlySelf: false, emitEvent: true});
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  display(record: ChipsModel<any>) {
    return record.name;
  }

  ngOnInit() {
    this.recordControl.updateValueAndValidity({onlySelf: false, emitEvent: true});
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['allRecords'] && this.availableRecords) {
      setTimeout(() => {
        this.recordControl.updateValueAndValidity({onlySelf: false, emitEvent: true});
      });
    }
  }

  get hasRecords() {
    return this.records && this.records.length > 0;
  }

  private _filter(value: any): ChipsModel<any>[] {
    let filterValue = '';
    if (value.type === 'chips-model') {
      filterValue = value.name;
    } else {
      if (typeof value === 'string') {
        filterValue = value.toLowerCase();
      }
    }
    return this.diffList.filter(record => record?.name.toLowerCase().indexOf(filterValue) === 0);
  }

}
