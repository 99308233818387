import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
  @Input() imageSrc = '';
  @Input() canDeleteImage: boolean = false;

  @Output() changeImage = new EventEmitter<any>();
  @Output() deleteImage = new EventEmitter<any>();
  @Output() imageLoaded = new EventEmitter<any>();

  @HostBinding('class.avatar') randomColor = false;
  @HostBinding('class.hover-image') @Input() canChangeImage: boolean = false;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageSrc']) {
      this.computeRandomAvatar();
    }
  }

  computeImageSrc() {
    if (!this.imageSrc) {
      return 'assets/images/default_user.png';
    } else {
      return this.imageSrc;
    }
  }

  computeRandomAvatar() {
    this.randomColor = !this.imageSrc;
  }

  onImageUploadClick() {
    if (this.canChangeImage) {
      this.changeImage.emit();
    }
  }

  onImageDeleteClick() {
    // TODO: Add confirmation modal for image delete.
    // this.confirmationModal.open();
  }

  onConfirmationClicked() {
    if (this.canDeleteImage) {
      this.deleteImage.emit();
    }
  }

  onLoad() {
    if (this.imageSrc) {
      this.imageLoaded.emit();
    }
  }
}
