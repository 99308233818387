<app-appbar [logo]="'assets/images/logo.svg'"
            [themes]="themes"
            [theme]="theme$ | async"
            [languages]="languages"
            [compact]="true"
            (themeChange)="onChangeTheme($event)"
            (languageChange)="onLanguageChange($event)">
  <router-outlet></router-outlet>
</app-appbar>

<app-tour-step-template></app-tour-step-template>
