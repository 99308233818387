import { inject } from '@angular/core';
import {
  CanActivateFn,
  Router,
  UrlTree
} from '@angular/router';
import { environment_token } from '../ux.module';

export const DevModeGuard: CanActivateFn = (): boolean | UrlTree => {
  const router = inject(Router);
  const environment = inject(environment_token);

  // Check if the environment is set to development
  if (environment.production === false) {
    // Allow access if in development mode
    return true;
  }

  // If not in development mode, redirect to a specified route
  return router.createUrlTree(['/en/app/custom']);
};
