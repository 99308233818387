import { Component, ContentChild, forwardRef, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-material-tab',
  template: ''
})
export class MaterialTabComponent implements OnInit {

  @ContentChild(forwardRef(() => 'content'), {static: false}) content: TemplateRef<any>;

  constructor() {
  }

  ngOnInit() {
  }
}
