import { AMRoute } from './route.models';

// Root
export class RootRoutes {
    public static Error404: AMRoute = {
        path: 'error404',
        url: '/error404',
        name: 'error404'
    };
}

// Presentation
export class PresentationRoutes {
    public static Home: AMRoute = {
        path: '',
        url: '/',
        name: 'home'
    };

    public static HowItWorks: AMRoute = {
        path: 'how',
        url: '/how',
        name: 'how'
    };

    public static TermsAndConditions: AMRoute = {
        path: 'terms-and-conditions',
        url: '/terms-and-conditions'
    };

    public static Gdpr: AMRoute = {
        path: 'gdpr',
        url: '/gdpr'
    };

    public static PrivacyPolicy: AMRoute = {
        path: 'privacy-policy',
        url: '/privacy-policy'
    };

    public static ContactUs: AMRoute = {
        path: 'contact',
        url: '/contact',
        name: 'contact'
    };

    public static ThankYouPage: AMRoute = {
        path: 'thank-you',
        url: '/thank-you',
        name: 'thank-you'
    };

    public static DemoPage: AMRoute = {
        path: 'demo',
        url: '/demo',
        name: 'demo'
    };
}


export class EstimateRoutes {
    public static root: AMRoute = {
        path: 'estimate',
        url: '/estimate',
        name: 'estimate'
    };
}

export class AuthRoutes {
    public static root: AMRoute = {
        path: 'auth',
        url: '/auth'
    };

    public static Login: AMRoute = {
        path: 'login',
        url: '/auth/login'
    };

    public static Register: AMRoute = {
        path: 'register',
        url: '/auth/register'
    };

    public static ConfirmEmail: AMRoute = {
        path: 'confirm-email',
        url: '/auth/confirm-email'
    };

    public static ForgotPassword: AMRoute = {
        path: 'forgot-password',
        url: '/auth/forgot-password'
    };

    public static ResetPassword: AMRoute = {
        path: 'reset-password',
        url: '/auth/reset-password'
    };
}

// App
export class AppRoutes {
    public static root: AMRoute = {
        path: 'app',
        url: '/app',
        name: 'app'
    };
}
