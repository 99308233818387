<ng-container *ngIf="(selectedModule$ | async) as module; else loading">
  <div class="details">
    <p class="title">{{ ('pages.estimate.modules.' + module.key + '.name') | translate }}
      ({{ 'general.texts.module' | translate }})</p>
    <app-circle-card class="image"
                     flavor="module"
                     [fixed]="true"
                     name="{{('pages.estimate.modules.'+ module.key + '.name') | translate}}"
                     content="assets/icons/modules/module_{{module.key}}_icon.svg">
    </app-circle-card>
    <p class="description">{{ 'pages.estimate.modules.' + module.key + '.description' | translate }}</p>
    <div class="features">
      <div class="feature" *ngFor="let feature of module.features">
        <app-feature-card
          content="{{ 'pages.estimate.modules.' + module.key + '.features.' + feature.key | translate }}"></app-feature-card>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <app-loading-spinner></app-loading-spinner>
</ng-template>
