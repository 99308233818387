import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

import { NGXLogger } from 'ngx-logger';
import { PagedResponse } from '../../../models/paged-response.model';
import { QueryConfig } from '../../../models/query-config.utils';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnChanges {

  @ViewChild(MatPaginator, {read: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() data: PagedResponse<any>;
  @Input() config: QueryConfig;
  @Input() imageColumn: string;
  @Input() tagsList: string[];
  @Input() columns: any[];
  @Input() actionColumns: any[];
  @Input() pageSizeOptions = [5, 10, 20];
  @Input() isLoading = false;
  @Input() formatColumnsData = false;

  @HostBinding('class.compact') @Input() compact = false;

  @Output() recordSelected = new EventEmitter<any>();
  @Output() recordAction = new EventEmitter<any>();
  @Output() pageChange = new EventEmitter<any>();

  displayedColumns: any[] = [];
  displayedActionColumns: any[] = [];

  dataSource: MatTableDataSource<any>;
  currentLang = 'en';

  constructor(private translateService: TranslateService, private logger: NGXLogger) {
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['columns']) {
      this.displayedColumns = [];
      this.columns.forEach(column => {
        this.displayedColumns.push(column.value);
      });
    }
    if (changes['actionColumns']) {
      this.displayedActionColumns = [];
      this.actionColumns.forEach(column => {
        this.displayedActionColumns.push(column.action);
      });
    }
    if (changes['data'] && this.data) {
      this.dataSource = new MatTableDataSource(this.data.items);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  translateHeaderName(columnKey: any) {
    return this.translateService.instant(columnKey);
  }

  onSelect(record: any) {
    this.logger.info('onClick:: Item was selected: ', record);
    this.recordSelected.emit(record);
  }

  onAction(record: any, action: any) {
    this.recordAction.emit({element: record, action});
  }

  onPageChange(pageEvent: PageEvent) {
    this.pageChange.emit(pageEvent);
  }

  get matDefinedColumns() {
    return [...this.imageColumn ? ['image'] : [], ...this.displayedColumns, ...this.displayedActionColumns];
  }

  get page() {
    return this.config?.pager?.page;
  }

  get pageSize() {
    return this.config?.pager?.pageSize;
  }

  get hasActions() {
    return this.actionColumns && this.actionColumns.length > 0;
  }
}
