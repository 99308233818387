<app-material id="buttons" [material]="false">
  <app-material-header>
    <h2 class="mat-headline-3">{{ 'ux.buttons.title' | translate }} </h2>
  </app-material-header>
  <app-material-body>
    <h3 class="mat-headline-5">Icon Button</h3>
    <section id="icons">
      <app-icon-button type="simple"
                       (appClick)="isIconButtonSelected = !isIconButtonSelected"
                       [selected]="isIconButtonSelected">
        wb_sunny
      </app-icon-button>
      <app-icon-button type="material"
                       (appClick)="isIconButtonSelected = !isIconButtonSelected"
                       [selected]="isIconButtonSelected">
        dark_mode
      </app-icon-button>

      <app-selection-indicator [selected]="isSelectionIndicatorSelected"
                               (appClick)="isSelectionIndicatorSelected = !isSelectionIndicatorSelected">

      </app-selection-indicator>

      <app-icon-button type="circular"
                       (appClick)="isIconButtonSelected = !isIconButtonSelected"
                       [selected]="isIconButtonSelected">
        dark_mode
      </app-icon-button>
    </section>

    <h3 class="mat-headline-5">Simple Button</h3>
    <section id="simple">
      <app-button>
        Rounded
      </app-button>
      <app-button icon="wb_sunny"
                  [selected]="isRoundedButtonSelected"
                  (appClick)="isRoundedButtonSelected = !isRoundedButtonSelected">
        Rounded + Icon
      </app-button>
      <app-button icon="wb_sunny"
                  type="tab"
                  [selected]="isTabButtonSelected"
                  (appClick)="isTabButtonSelected = !isTabButtonSelected">
        Tab
      </app-button>
      <app-button icon="wb_sunny"
                  type="menu"
                  [selected]="isMenuButtonSelected"
                  (appClick)="isMenuButtonSelected = !isMenuButtonSelected">
        Menu Button
      </app-button>
    </section>
  </app-material-body>
</app-material>

<app-material id="inputs" [material]="false">
  <app-material-header>
    <h2 class="mat-headline-3">{{ 'ux.inputs.title' | translate }} </h2>
  </app-material-header>
  <app-material-body>
    <h3 class="mat-headline-5">{{ 'ux.inputs.text' | translate }}</h3>
    <app-input type="text"
               label="{{'ux.inputs.text' | translate}}"
               [ngStyle]="{width: inputWidth}"
               [formControl]="control('text')">
    </app-input>
    <h3 class="mat-headline-5">{{ 'ux.inputs.email' | translate }}</h3>
    <app-input type="email"
               label="{{'ux.inputs.email' | translate}}"
               [ngStyle]="{width: inputWidth}"
               [formControl]="control('email')">
    </app-input>
    <h3 class="mat-headline-5">{{ 'ux.inputs.password' | translate }}</h3>
    <app-input type="password"
               label="{{'ux.inputs.password' | translate}}"
               [ngStyle]="{width: inputWidth}"
               [formControl]="control('password')">
    </app-input>
    <h3 class="mat-headline-5">{{ 'ux.inputs.number' | translate }}</h3>
    <app-input type="number"
               label="{{'ux.inputs.number' | translate}}"
               [ngStyle]="{width: inputWidth}"
               [formControl]="control('number')">
    </app-input>
    <h3 class="mat-headline-5">{{ 'ux.inputs.date' | translate }}</h3>
    <app-input type="date"
               label="{{'ux.inputs.date' | translate}}"
               [formControl]="control('date')"
               startView="month">
    </app-input>

    <h3 class="mat-headline-5">{{ 'ux.inputs.switch' | translate }}</h3>
    <app-input-switch
      label="{{'ux.inputs.switch' | translate}}"
      labelPosition="after"
      [formControl]="control('switch')">
    </app-input-switch>

    <h3 class="mat-headline-5">{{ 'ux.inputs.dropdown' | translate }}</h3>
    <app-input-dropdown-autocomplete
      label="{{'ux.inputs.dropdown' | translate}}"
      [options]="inputOptions"
      [formControl]="control('dropdown')"
      [ngStyle]="{width: inputWidth}">
    </app-input-dropdown-autocomplete>

    <h3 class="mat-headline-5">{{ 'ux.inputs.multinput' | translate }}</h3>
    <app-input-multitext
      label="{{'ux.inputs.multinput' | translate}}"
      [formControl]="control('multi')"
      [limit]="3"
      [ngStyle]="{width: inputWidth}">
    </app-input-multitext>

    <h3 class="mat-headline-5">{{ 'ux.inputs.chips' | translate }}</h3>
    <app-input-chips class="input-field"
                     label="{{'ux.inputs.chips' | translate}}"
                     [availableRecords]="personnelChips"
                     [allowExtraValues]="false"
                     [formControl]="control('chips')"
                     [ngStyle]="{width: inputWidth}">
    </app-input-chips>

    <h3 class="mat-headline-5">{{ 'ux.inputs.radio' | translate }}</h3>
    <app-input-radio
      orientation="horizontal"
      [options]="inputOptions"
      [multiple]="false"
      [formControl]="control('radio')">
    </app-input-radio>

    <h3 class="mat-headline-5">{{ 'ux.inputs.textarea' | translate }}</h3>
    <app-input-textarea label="{{'ux.inputs.textarea' | translate}}"
                        [ngStyle]="{width: inputWidth}"
                        [formControl]="control('textarea')">
    </app-input-textarea>
  </app-material-body>
</app-material>


<app-material id="cards" [material]="false">
  <app-material-header>
    <h2 class="mat-headline-3">{{ 'ux.content.title' | translate }} </h2>
  </app-material-header>
  <app-material-body>
    <h3 class="mat-headline-5">{{ 'ux.content.cards' | translate }} </h3>
    <section id="card">
      <app-card>
        <app-card-avatar
          type="color"
          background="#030303">
        </app-card-avatar>
        <app-card-content [tooltip]="colorCardText">
          {{ colorCardText }}
        </app-card-content>
      </app-card>
      <app-card>
        <app-card-avatar
          type="image"
          background="/assets/images/m3-light.jpg">
          <img src="assets/icons/size/size_large.svg">
        </app-card-avatar>
        <app-card-content [tooltip]="imageCardText">
          {{ imageCardText }}
        </app-card-content>
      </app-card>
      <app-card [selectable]="true"
                [selected]="isCardSelected"
                (appClick)="isCardSelected = !isCardSelected">
        <app-card-avatar
          type="image"
          background="/assets/images/m3-light.jpg">
          <img src="assets/icons/size/size_large.svg">
        </app-card-avatar>
        <app-card-content [tooltip]="truncatedCardContent">
          {{ truncatedCardContent }}
        </app-card-content>
      </app-card>
    </section>

    <h3 class="mat-headline-5">{{ 'ux.content.steppers' | translate }} </h3>
    <section id="stepper">
      <app-stepper #stepper
                   orientation="horizontal"
                   [selectedIndex]="selectedStepIndex"
                   (selectionChange)="onStepChange($event)">
        <app-step label="tashi">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been
          the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book. It has survived not only five centuries,
          but also the leap into electronic typesetting, remaining essentially unchanged. It was
          popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
          and more recently with desktop publishing software like Aldus PageMaker including versions of
          Lorem Ipsum.

          Why do we use it?
          It is a long established fact that a reader will be distracted by the readable content of a page
          when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal
          distribution of letters, as opposed to using 'Content here, content here', making it look like
          readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as
          their default model text, and a search for 'lorem ipsum' will uncover many web sites still in
          their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on
          purpose (injected humour and the like).


          Where does it come from?
          Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of
          classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a
          Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin
          words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in
          classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32
          and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
          written in 45 BC. This book is a treatise on the theory of ethics, very popular during the
          Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in
          section 1.10.32.

          The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested.
          Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced
          in their exact original form, accompanied by English versions from the 1914 translation by H.
          Rackham.
        </app-step>
        <app-step label="mas">
          Again
        </app-step>
        <app-step label="gozaymas">
          And again!
        </app-step>
        <app-step-actions>
          <app-button *ngIf="stepper.isFirstStep"
                      icon="arrow_back_ios"
                      (appClick)="stepper.previous()">
            {{ 'general.texts.back' | translate }}
          </app-button>
          <app-button *ngIf="!stepper.isLastStep"
                      icon="arrow_forward_ios"
                      (appClick)="stepper.next()">
            {{ 'general.texts.next' | translate }}
          </app-button>
        </app-step-actions>
      </app-stepper>
    </section>

    <h3 class="mat-headline-5">{{ 'ux.content.gantt' | translate }} </h3>
    <section id="gantt">
      <app-gantt-chart [title]="title"
                       [columnNames]="columnNames"
                       [data]="moduleChartData"
                       [options]="options">
      </app-gantt-chart>
    </section>
  </app-material-body>
</app-material>


