import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-footer',
  templateUrl: './material-footer.component.html',
  styleUrls: ['./material-footer.component.scss']
})
export class MaterialFooterComponent implements OnInit {
  @HostBinding('class.btns-inline') @Input() inline = false;

  constructor() {
  }

  ngOnInit() {
  }
}
