import { Component, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {

  @HostBinding('class') @Input() type: 'simple' | 'material' | 'circular' = 'simple';
  @Input() value: any;
  @Input() selected = false;

  @Output() appClick = new EventEmitter<any>();
  isHover = false;

  @HostListener('mouseover') mouseover() {
    this.isHover = true;
  }

  @HostListener('mouseleave') mouseleave() {
    this.isHover = false;
  }

  @HostListener('click', ['$event'])
  public toggle(event: PointerEvent): void {
    this.appClick.emit(this.value);
  }

}
