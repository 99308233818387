<div class="banner-container">
    <img src="../../../../../../assets/images/home_banner.png" alt="Home Banner"/>
    <div class="content">
        <h1>{{'pages.home.banner.header' | translate}}</h1>
        <p [innerHTML]="'pages.home.banner.subheader' | translate"></p>
        <app-button id="start-estimate-home-top-cta">
            {{'pages.home.banner.estimate' | translate | uppercase}}
        </app-button>
    </div>
</div>

<div class="description">
    <app-full-row-section
            index="1"
            orientation="s-reverse"
            [parentScroll]="scroll"
            image="assets/landing/img1.svg"
            heading="{{'pages.home.description.sections.one.header' | translate}}"
            text="{{'pages.home.description.sections.one.text' | translate}}">
    </app-full-row-section>
    <app-full-row-section
            index="2"
            orientation="s-reverse"
            [parentScroll]="scroll"
            image="assets/landing/img2.svg"
            heading="{{'pages.home.description.sections.two.header' | translate}}"
            text="{{'pages.home.description.sections.two.text' | translate}}">
    </app-full-row-section>
    <app-full-row-section
            index="3"
            orientation="s-reverse"
            [parentScroll]="scroll"
            image="assets/landing/img3.svg"
            heading="{{'pages.home.description.sections.three.header' | translate}}"
            text="{{'pages.home.description.sections.three.text' | translate}}">
    </app-full-row-section>
    <app-full-row-section
            index="4"
            orientation="s-reverse"
            [parentScroll]="scroll"
            (cta)="onCTA()"
            image="assets/landing/img4.svg"
            heading="{{'pages.home.description.sections.four.header' | translate}}"
            text="{{'pages.home.description.sections.four.text' | translate}}"
            ctaText="{{'pages.home.description.sections.four.cta' | translate}}"
            ctaId="start-estimate-home-bottom-cta">
    </app-full-row-section>
</div>
