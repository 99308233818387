import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss']
})
export class TextboxComponent implements OnInit {

  @Input() @HostBinding('class.responsive') responsive = false;
  @Input() width = 0;
  @Input() height = 0;

  constructor() {
  }

  ngOnInit() {
  }

}
