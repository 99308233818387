import {
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { StepComponent } from './components/step/step.component';
import { StepActionsComponent } from './components/step-actions/step-actions.component';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {
  @ViewChild(MatStepper) stepper: MatStepper;
  @ContentChildren(StepComponent) steps: QueryList<StepComponent>;
  @ContentChild(StepActionsComponent) actions: StepActionsComponent;
  @Input() selectedIndex = -1;
  @Input() headerLabelPosition: 'end' | 'bottom' = 'end';
  @Input() linear = false;
  @Input() orientation: 'horizontal' | 'vertical' = 'horizontal';

  @HostBinding('class.mat-app-background') bck = true;

  @Output() stepperDone = new EventEmitter<any>();
  @Output() selectionChange = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit(): void {
  }

  selectionChangeStepper(event: any) {
    this.selectionChange.emit(event.selectedIndex);
  }

  next() {
    this.stepper.next();
  }

  previous() {
    this.stepper.previous();
  }

  get isLastStep() {
    return this.selectedIndex === this.stepper?._steps?.length - 1;
  }

  get isFirstStep() {
    return this.selectedIndex > 0;
  }

}
