import { Component, ContentChild, forwardRef, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-card-actions',
  templateUrl: './card-actions.component.html',
  styleUrls: ['./card-actions.component.scss']
})
export class CardActionsComponent {
  @ContentChild(forwardRef(() => 'content'), {static: false}) content!: TemplateRef<any>;
}
