import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatChipSelectionChange } from '@angular/material/chips';

/**
 * WARNING: When setting the flavor of orientation to 'vertical' the radio selectable area is
 * reduced to the area occupied by the radio's label. This is how it should work according to the material
 * library. This is why I had to use a 'vertical' class for creating a custom experience.
 */
@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputRadioComponent,
      multi: true
    }
  ]
})
export class InputRadioComponent implements ControlValueAccessor {

  @Input() label = '';

  @Input() formControl: FormControl;
  @Input() options: any[] = [];
  @Input() horizontalAlign = false;

  @Input() multiple = false;

  @Input() orientation: 'vertical' | 'horizontal' = 'horizontal';
  @Output() optionChange = new EventEmitter<any>();

  private selected: any[] = [];

  private onChangeCallBack: (_: any) => {};
  private onTouchedCallBack: (_: any) => {};

  writeValue(value: any): void {
    this.selected = value;
  }

  registerOnChange(fn: any): void {
    this.onChangeCallBack = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouchedCallBack = fn;

  }

  onSelectionChange($event: MatChipSelectionChange, index: number) {

    if (!this.multiple) {
      this.options.map((option: any) => {
        option.selected = false;
        return option
      });
    }
    this.options[index].selected = $event.selected;
    this.onChangeCallBack(this.options);
    this.onTouchedCallBack(this.options);
  }
}
