import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Interceptor class for adding the JWT Authorization header to all the requests made to the API.
 */

@Injectable()
export class AuthorizationHeaderInterceptor implements HttpInterceptor {
  readonly AUTHORIZATION_HEADER = 'Authorization';
  readonly X_CORRELATION_ID = 'X-Correlation-ID';

  getRandom(length: number) {
    return Math.floor(Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const userToken = localStorage.getItem('user_token');
    let headers = userToken ? req.headers.set(this.AUTHORIZATION_HEADER, 'Bearer ' + userToken) : req.headers;
    headers = headers.set(this.X_CORRELATION_ID, this.getRandom(6).toString());
    const clonedRequest = req.clone({headers});

    return next.handle(clonedRequest);
  }
}
