<mat-chip-listbox [aria-orientation]="orientation"
                  [multiple]="multiple"
                  [class.vertical]="orientation === 'vertical'"
                  role="list">
  <mat-chip-option *ngFor="let chip of options; let i = index;"
                   [selected]="chip.selected"
                   role="listitem"
                   (selectionChange)="onSelectionChange($event, i)"
                   [color]="chip.color">
    {{chip.name}}
  </mat-chip-option>
</mat-chip-listbox>
<app-form-error-list
    *ngIf="formControl?.invalid && formControl?.touched"
    [errorMap]="formControl?.errors">
</app-form-error-list>
