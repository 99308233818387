import { Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList } from '@angular/core';

import { MaterialTabComponent } from './material-tab/material-tab.component';

@Component({
  selector: 'app-material-tabs',
  templateUrl: './material-tabs.component.html',
  styleUrls: ['./material-tabs.component.scss']
})
export class MaterialTabsComponent implements OnInit {
  @ContentChildren(MaterialTabComponent) tabComponents: QueryList<MaterialTabComponent>;

  @Input() tabs: any[];

  @Output() tabChange = new EventEmitter<number>();

  selectedTabIndex = 0;

  constructor() {
  }

  ngOnInit(): void {
  }

  changeTabByIndex(index: number) {
    this.selectedTabIndex = index;
  }

  get hasLinks() {
    return this.tabs && this.tabs.length && this.tabs[0].path;
  }

  onTabChanged(tab: any) {
    this.tabChange.emit(tab);
  }
}
