import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  forwardRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-application-card-avatar',
  templateUrl: './application-card-avatar.component.html',
  styleUrls: ['./application-card-avatar.component.scss']
})
export class ApplicationCardAvatarComponent implements AfterViewInit {
  @ContentChild(forwardRef(() => 'content'), {static: false}) content!: TemplateRef<any>;
  @ViewChild('cover') cover!: ElementRef;

  @Input() type: 'color' | 'image' = 'color';

  @Input() background = '';

  constructor(private renderer: Renderer2) {

  }

  ngAfterViewInit(): void {
    const background = this.type === 'color' ? this.background : `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${this.background}) no-repeat center`
    this.renderer.setStyle(this.cover.nativeElement, 'background', background);
  }
}
