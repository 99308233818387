<div class="left-inputs">
  <h3 class="mat-headline-5">{{'ux.inputs.text' | translate}}</h3>
  <app-input type="text"
             label="{{'ux.inputs.text' | translate}}"
             [ngStyle]="{width: inputWidth}"
             [formControl]="control('text')">
  </app-input>
  <h3 class="mat-headline-5">{{'ux.inputs.email' | translate}}</h3>
  <app-input type="email"
             label="{{'ux.inputs.email' | translate}}"
             [ngStyle]="{width: inputWidth}"
             [formControl]="control('email')">
  </app-input>
  <h3 class="mat-headline-5">{{'ux.inputs.password' | translate}}</h3>
  <app-input type="password"
             label="{{'ux.inputs.password' | translate}}"
             [ngStyle]="{width: inputWidth}"
             [formControl]="control('password')">
  </app-input>
  <h3 class="mat-headline-5">{{'ux.inputs.number' | translate}}</h3>
  <app-input type="number"
             label="{{'ux.inputs.number' | translate}}"
             [ngStyle]="{width: inputWidth}"
             [formControl]="control('number')">
  </app-input>
  <h3 class="mat-headline-5">{{'ux.inputs.date' | translate}}</h3>
  <app-input type="date"
             label="{{'ux.inputs.date' | translate}}"
             [formControl]="control('date')"
             startView="month">
  </app-input>

  <h3 class="mat-headline-5">{{'ux.inputs.switch' | translate}}</h3>
  <app-input-switch
      label="{{'ux.inputs.switch' | translate}}"
      labelPosition="after"
      [formControl]="control('switch')">
  </app-input-switch>
</div>


<div class="right-inputs">

  <h3 class="mat-headline-5">{{'ux.inputs.dropdown' | translate}}</h3>
  <app-input-dropdown-autocomplete
      label="{{'ux.inputs.dropdown' | translate}}"
      [options]="options"
      [formControl]="control('dropdown')"
      [ngStyle]="{width: inputWidth}">
  </app-input-dropdown-autocomplete>

  <h3 class="mat-headline-5">{{'ux.inputs.multinput' | translate}}</h3>
  <app-input-multitext
      label="{{'ux.inputs.multinput' | translate}}"
      [formControl]="control('multi')"
      [limit]="3"
      [ngStyle]="{width: inputWidth}">
  </app-input-multitext>

  <h3 class="mat-headline-5">{{'ux.inputs.chips' | translate}}</h3>
  <app-input-chips class="input-field"
                   label="{{'ux.inputs.chips' | translate}}"
                   [availableRecords]="personnelChips"
                   [allowExtraValues]="false"
                   [formControl]="control('chips')"
                   [ngStyle]="{width: inputWidth}">
  </app-input-chips>

  <h3 class="mat-headline-5">{{'ux.inputs.radio' | translate}}</h3>
  <app-input-radio
      orientation="horizontal"
      [options]="options"
      [multiple]="false"
      [formControl]="control('radio')">
  </app-input-radio>

  <h3 class="mat-headline-5">{{'ux.inputs.textarea' | translate}}</h3>
  <app-input-textarea label="{{'ux.inputs.textarea' | translate}}"
                      [ngStyle]="{width: inputWidth}"
                      [formControl]="control('textarea')">
  </app-input-textarea>


</div>
