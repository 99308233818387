<h1 class="terms-header"> {{'general.texts.termsAndConditions' | translate}}</h1>
<app-textbox class="terms-box"
             [responsive]="true">
  <pre>
  TERMENI ȘI CONDIȚII de UTILIZARE A SITE-ULUI WEB (“Terms & Conditions”)
www.appmake.io
Versiunea [01.01.2021]

Scopul T&C
Acești termeni și condiții de utilizare conțin regulile pentru accesarea și folosirea de către utilizatori (în continuare „dvs.” sau „Utilizatorul”) a website-ului www.appmake.io („website-ul”), care este deținut și operat de către APPMAKE IO SRL, societate de drept român, înregistrată la registrul comerțului cu nr. J12/4146/2020, identificator unic european ROONRC.J12/4146/14.12.2020, CUI 43447941, cu sediul în pe Str. Gruia, Nr. 27, 400171 Cluj-Napoca, Cluj (denumită în cele ce urmează “appmake.io”, “noi” sau orice alt termen similar).

Puteți naviga și utiliza website-ul nostru doar cu respectarea termenilor și condițiilor impuși de către appmake.io.

Terminologie
Site → Setul de pagini web care sunt publicate de către appmake.io (site-ul pe care vă aflați)

Utilizator → Orice persoană care vizitează site-ul appmake.io fie în scop de informare fie cu interes comercial

Conținut → Orice formă de proprietate intelectuală care este publicată de către appmake.io pe site (ilustrații, articole text, imagini, texte, cod sursă, animații, sunete)

Funcționalitate → Orice formă de funcționalitate software care este expusă de către appmake.io utilizatorilor.
Acordul dvs.
Prin continuarea utilizării acestui website ne confirmați că ați citit, înțeles și acceptat conținutul Termenilor și Condițiilor de pe site-ul appmake.io și că îndepliniți toate cerințele legale (varsta, capacitate, autorizări etc.) pentru a vă asuma și executa în mod valabil acești Termeni și Condiții.
Conținutul și funcționalitățile puse la dispoziție

Conținut
appmake.io este o companie care își desfășoară activitățile în domeniul implementării soluțiilor software la comandă. În acest sens, appmake.io pune la dispoziția utilizatorilor conținut relevant legat de:
Cultura internă appmake
Procesul tehnologic prin care se obțin produsele și platformele software
Procesul tehnologic de estimare a efortului de implementare pentru soluții și aplicații software
Algoritmi de calcul
Articole și Știri cu înclinație înspre domeniul IT
Oferta comercială de la partenerii appmake.io

Funcționalități
În ce ține de funcționalități, appmake.io pune la dispoziția clienților acestora mai multe funcționalități software care aduc beneficiu utilizatorilor:
O modalitate de a calcula în timp real estimările de timp și preț pentru dezvoltarea platformelor software la comandă
O modalitate de a genera un set de scenarii de utilizare pentru utilizatori
etc

appmake.io își rezervă toate drepturile asupra datelor și a funcționalităților existente în site-ul web, care sunt puse la dispoziția utilizatorilor, fără a fi nevoită să ofere explicații sau detalii legat de funcționalitățile sau datele în temă (acesta constituind un secret comercial)


Condiții de utilizare

Utilizarea site-ului web pus la dispoziție de către appmake.io, implică acceptul dumneavoastră față de termenii și condițiile impuse de către aceasta și denotă gradul dumneavoastră de informare asupra la politicile expuse de către appmake.io:
Politica Cookies → https://appmake.io/privacy-policy
Politica de confidențialitate → https://appmake.io/gdpr

Utilizatorul are obligația de a se documenta despre politicile și termeni și condiții de utilizare a site-ului înainte de a utiliza site-ul web.
Utilizatorul poate folosi website-ul și poate beneficia de Servicii cu respectarea următoarelor obligații suplimentare:

Să nu interfereze cu sau să perturbe în vreun fel securitatea website-ului sau a serviciilor, conturilor, serverelor, rețelelor sau resurselor conectate la sau accesibile prin website sau prin website-uri afiliate sau legate de acesta;
Să nu interfereze cu sau să perturbe în vreun fel utilizarea acestui website, a website-urilor afiliate acestuia sau a website-urilor legate de acesta de către alte persoane;
Să nu foloseasca acest website, website-urile afiliate acestuia sau website-urile legate de acesta pentru activități ilegale, care includ, dar nu se limitează la spam, junk mail, chain leters etc.
Să nu încerce să obțină accesul neautorizat la acest website sau la părți ale website-ului care nu sunt general disponibile publicului

În general, să aibă o conduită legală, de bună-credință și neabuzivă în legătură site-ului web, noi, precum și orice altă terță parte.

Conținutul și disponibilitatea website-ului
Conținutul postat pe website, inclusiv text sau imagini, are un caracter informativ.

Astfel, appmake.io își rezervă dreptul de a înceta, schimba, suspenda sau întrerupe orice aspect care ține de acest site web, inclusiv disponibilitatea oricărui conținut, la discreția acesteia.

appmake.io, la discreția sa, poate impune anumite limite cu privire la funcționalitățile website-ului sau poate restrânge accesul la anumite părți sau la întreg site-ul web. În mod particular, appmake.io poate să vă interzică accesul la website, la orice moment.
Postările cu privire la produsele și serviciile afișate pe website pot fi modificate la orice moment, fără vreo înștiințare prealabilă. Deși facem eforturi să menținem conținutul de pe site-ul web actualizat, nu vom putem garanta că acesta va fi mereu la zi sau că produsele sau serviciile postate vor fi disponibile în forma prezentată pe site-ul web.

Raspunderea Utilizatorului
Utilizatorii vor despăgubii appmake.io pentru orice pierdere sau prejudiciu care rezultă din nerespectarea sau încălcarea acestor termene și condiții.

Răspunderea appmake.io
Informația afișată pe site-ul web nu creează nicio obligație legală sau răspundere pentru appmake.io sau afiliații săi. De asemenea, appmake.io nu oferă nicio garanție cu privire la prejudiciul suferit de către utilizator prin utilizarea acestui site web.

Proprietate intelectuală
Orice conținut furnizat prin intermediul website-ului sau inclus, inclusiv, dar fără a se limita la: este deținut de către appmake.io sau licențiat acestuia și/sau afiliaților săi și este protejat de legea aplicabilă. Conținutul nu va putea fi modificat, copiat, distribuit, reprodus, republicat, descărcat, afișat, postat, transmis sau transferat în orice formă, fără autorizarea scrisă a appmake.io.


Jurisdicție
Acești Termeni și Condiții sunt guvernați de legea română și orice dispută cu privire la acestea va fi soluționată de instanțele competente din România.


Modificări ale Termenilor și Condițiilor
Din timp în timp este posibil să modificăm acești Termeni și Condiții pentru a reflecta schimbările legislative sau modificările politicilor noastre interne, ale site-ului web sau ale tehnologiei pe care o folosim.

Astfel, termenii și condițiile vor fi versionate în bază la data publicării acestora




  </pre>
</app-textbox>
