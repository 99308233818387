<table mat-table [dataSource]="dataSource" matSort>
  <ng-container matColumnDef="image">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" (click)="onSelect(element)">
      <app-avatar [imageSrc]="element[imageColumn]"></app-avatar>
    </td>
  </ng-container>

  <ng-container *ngFor="let column of columns"
                [matColumnDef]="column.value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ translateHeaderName(column.label) }} </th>
    <td mat-cell *matCellDef="let element"
        (click)="onSelect(element)" [matTooltip]="element[column.value]" appShowIfTruncated> {{ element[column.value] ? element[column.value] : 'N/A' }} </td>
  </ng-container>

  <ng-container *ngFor="let actionColumn of actionColumns"
                [matColumnDef]="actionColumn.action">
    <th class="action-header-cell" mat-header-cell *matHeaderCellDef></th>
    <td class="action-cell" mat-cell *matCellDef="let element">
      <button mat-icon-button
              class="action-button"
              (click)="onAction(element, actionColumn.action)">
        <mat-icon *ngIf="actionColumn.svgIcon; else simpleIcon">
        </mat-icon>
        <ng-template #simpleIcon>
          <mat-icon>{{actionColumn.icon}}</mat-icon>
        </ng-template>
      </button>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="matDefinedColumns" ></tr>
  <tr mat-row *matRowDef="let row; columns: matDefinedColumns;"
      [class.actionable]="hasActions"></tr>

  <!-- Row shown when there are no items. -->
  <tr class="mat-row no-data-row" *matNoDataRow>
    <td class="mat-cell" [colSpan]="matDefinedColumns.length">
      <div class="no-items">{{'general.texts.emptyList' | translate}}</div>
    </td>
  </tr>
</table>

<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons
               [pageIndex]="page"
               [pageSize]="pageSize"
               [length]="data?.total"
               (page)="onPageChange($event)">
</mat-paginator>

<ng-template let-item="item">

</ng-template>
