<section class="presentation">
    <img class="img"
         [@scrollBottomEntranceAnimation]="bottomEntrance"
         [@scrollLeftEntranceAnimation]="leftEntrance"
         [@scrollRightEntranceAnimation]="rightEntrance"
         [src]="image"/>
    <div *ngIf="!hasCustomText"
         class="text">
        <div class="line"></div>
        <h1 class="title">{{heading}}</h1>
        <p class="description" [innerHTML]="text"></p>
        <button *ngIf="ctaText"
                (click)="ctaClick()"
                id="{{ctaId}}"
                class="cta">{{ctaText}}
        </button>
    </div>
    <div *ngIf="hasCustomText"
         class="text">
        <ng-content select="[custom]"></ng-content>
    </div>
</section>
