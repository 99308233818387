import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404Component } from '@ngnizer/ux';

const appRoutes: Routes = [
  {
    path: 'estimate',
    loadChildren: () => import('@ngnizer/estimate').then(m => m.EstimateModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/presentation/presentation.module').then(m => m.PresentationModule)
  },
  {
    path: 'demo',
    loadChildren: () => import('@ngnizer/ux').then(m => m.UxModule)
  },
  {path: 'error404', component: Error404Component},
  {path: '', redirectTo: 'estimate', pathMatch: 'full'},
  {path: '**', redirectTo: 'error404', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
